import { Col, Form, Row } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import FormInput from 'common/FormInput';
import Modal from 'common/Modal';
import {
  addMaterials,
  getMaterialsList,
} from 'features/sectionHeadFlow/experiments/redux/slice';
import { useDispatch } from 'react-redux';
import { MATERIAL_TYPES } from '..';
import { useCallback, useEffect, useState } from 'react';
import RemarksModal from 'common/RemarksModal';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { getMaterial } from 'features/sectionHeadFlow/redux/slice';
import { MATERIAL_MASTERS_FIELDS } from 'utilities/helpers';

type TList = {
  label: string;
  value: string;
};

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  renderedUOMList: TList[];
  onUomSearch: (val: string) => void;
  projectId: string | number;
  addMaterialsLoading: boolean;
  experimentId: string | null;
  folderId: string | undefined;
  isCreate: boolean;
  isView: boolean;
  isExternalChemist: boolean;
  uomList: any[];
};

export default function SolventModal({
  isOpen,
  onClose,
  renderedUOMList,
  onUomSearch,
  projectId,
  addMaterialsLoading,
  experimentId,
  folderId,
  isCreate,
  isView,
  isExternalChemist,
  uomList,
}: TProps) {
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const { getMaterialLoading, materialData } = useSelector(
    (state: RootState) => state.sectionHead
  );
  const [isLiquidUomSelected, setIsLiquidUomSelected] = useState(false);
  const [transformedMaterialNames, setTransformedMaterialNames] = useState<
    any[]
  >([]);

  const handleOnFinish = (values: any) => {
    setIsRemarksModalOpen(false);
    const payload = {
      ...values,
      project_id: projectId,
      is_manual_entry: true,
      uom_id: values.uom_master_id,
      type: MATERIAL_TYPES.solvents,
      experiment_id: experimentId || null,
      folder_id: folderId,
      change_remarks: values.remark,
      section: 'raw_material_details',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
      material_name: transformedMaterialNames.find(
        (item) => item.value === values.material_name
      )?.label,
    };
    dispatch(addMaterials(payload)).then((res: any) => {
      if (res?.payload?.success) {
        const experiment_id = res?.payload?.experiment_id || experimentId;
        const listPayload = {
          project_id: projectId,
          experiment_id,
        };
        onClose();
        form.resetFields();
        dispatch(getMaterialsList(listPayload));
      }
    });
  };

  const handleSubmit = (remark: string) => {
    form
      .validateFields()
      .then((values: any) => {
        if (isCreate || isView) {
          handleOnFinish({ ...values, remark });
        } else {
          if (isRemarksModalOpen) {
            handleOnFinish({ ...values, remark });
          } else {
            setIsRemarksModalOpen(true);
          }
        }
      })
      .catch(() => {});
  };

  //handle material search
  const handleSearch = (searchTerm: string, slug: string) => {
    if (searchTerm) {
      form.resetFields(MATERIAL_MASTERS_FIELDS.filter((val) => val !== slug));
      const payload = {
        material_fields: [
          {
            fields: MATERIAL_MASTERS_FIELDS.map((val) => ({
              field_name: val,
              filter: val === slug ? searchTerm : null,
            })),
          },
        ],
      };
      dispatch(getMaterial(payload));
    }
  };

  const debouncedSearch = useCallback(
    debounce((term: string, slug: string) => handleSearch(term, slug), 500),
    []
  );

  //loading master material in select fields
  useEffect(() => {
    if (materialData && !getMaterialLoading) {
      setTransformedMaterialNames(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[0]])
          .map((item) => ({ value: item.id, label: item.material_name }))
      );
    }
  }, [materialData]);

  const handleFormValueChange = (changedVal: any, values: any) => {
    if (changedVal.uom_master_id) {
      const uomCategory = uomList.find(
        (uom) => uom.id === values.uom_master_id
      )?.uom_category;
      if (uomCategory === 'Vol/Wt') {
        setIsLiquidUomSelected(true);
      } else {
        setIsLiquidUomSelected(false);
      }
    }
  };

  return (
    <Modal
      btnLoading={addMaterialsLoading}
      headerTitle="Solvent & Others"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      onAgree={() => form.submit()}
      onCancel={() => form.resetFields()}
    >
      <Form
        id="solvent_modal_form"
        className="!px-3"
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        onValuesChange={handleFormValueChange}
      >
        <Row gutter={10}>
          <Col span={24}>
            <FormInput
              marginBottom="medium"
              name="material_name"
              label={<span className="text-tertiary-dark">Material Name</span>}
              required
              validateMsg="Material Name is required"
              defaultStyle={false}
              placeholder="Enter material name"
              type="select"
              selectInputClasses="border-none focus-within:border-none  placeholder:text-primary"
              wrapperClasses={`rounded-md !m-0 !p-0 hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark`}
              onSearch={(t) => debouncedSearch(t, 'material_name')}
              loading={getMaterialLoading}
              options={transformedMaterialNames}
              optionRender={(option) => (
                <p className="text-xs">{option.label}</p>
              )}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24} sm={{ span: 12 }}>
            <Row>
              <Col span={16}>
                <FormInput
                  type="number"
                  name="quantity"
                  label={<span className="text-tertiary-dark">Quantity</span>}
                  inputClasses="p-2 font-open-sans shadow-none border focus-within:border-primary-dark hover:border-tertiary-highlight-200 rounded-e-none"
                  defaultStyle={false}
                  placeholder="Enter quantity"
                  marginBottom="small"
                />
              </Col>
              <Col span={8}>
                <FormInput
                  marginBottom="small"
                  popupClassName="select-dropdown"
                  name="uom_master_id"
                  label={<div className="invisible">uom</div>}
                  onSearch={onUomSearch}
                  type="select"
                  defaultStyle={false}
                  placeholder="UOM"
                  options={renderedUOMList}
                  selectInputClasses="selected-item-transform border-none focus-within:border-none  placeholder:text-primary select-placeholder"
                  wrapperClasses="rounded-e-md hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark !m-0"
                />
              </Col>
            </Row>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <FormInput
              name="wt_wt"
              label={
                <span className="text-tertiary-dark">Wt/Wt or Vol/Wt</span>
              }
              defaultStyle={false}
              placeholder="Enter Wt/Wt or Vol/Wt"
              type="number"
              marginBottom="small"
              suffix={isLiquidUomSelected ? 'Vol/Wt' : 'Wt/Wt'}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24} sm={{ span: 24 }}>
            <FormInput
              marginBottom="medium"
              name="source"
              label={<span className="text-tertiary-dark">Source</span>}
              defaultStyle={false}
              placeholder="Enter source"
            />
          </Col>
        </Row>
        {/*change remarks modal */}
        <RemarksModal
          isOpen={isRemarksModalOpen && isOpen}
          onApprove={(value) => handleSubmit(value)}
          loading={addMaterialsLoading}
          onClose={() => setIsRemarksModalOpen(false)}
        />
      </Form>
    </Modal>
  );
}
