import { useEffect } from 'react';
import { Flex, Spin, Button, Tooltip, Badge, Space } from 'antd';
import Table from 'common/Table';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { createUom } from 'features/Admin/redux/slice';
import { openNotification } from 'utilities/notification';
import UOMModal from './UOMModal';

type ListProps = {
  searchUom: string;
  uomList: any;
  uomListLoading: boolean;
  handelGetUomList: any;
  pagination: any;
  currentPage: number;
  setCurrentPage: any;
  PER_PAGE_ITEM: number;
  addUserLoading: boolean;
};

export default function ListView({
  searchUom,
  uomList,
  handelGetUomList,
  uomListLoading,
  pagination,
  currentPage,
  setCurrentPage,
  PER_PAGE_ITEM,
  addUserLoading,
}: ListProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDataById, setUserDataById] = useState({});

  interface payload {
    id: number;
    is_active: boolean;
  }

  const handleModalOpen = (record: any) => {
    console.log('record', record);
    setIsModalOpen(true);
    setUserDataById(record);
  };

  const handleModalClose = () => setIsModalOpen(false);

  const handleDeleteUOM = (id: number) => {
    console.log('>>uom deleted with id:', id);
    const payload: payload = {
      id: id,
      is_active: false,
    };
    dispatch(createUom(payload)).then((res: any) => {
      if (res.payload.success) {
        handelGetUomList();
      }
    });
  };

  const columns = [
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          UOM Code
        </label>
      ),
      dataIndex: 'uomCode',
      key: 'uomCode',
      width: '6%',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <p className="relative text-xs !px-0 font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </>
        );
      },
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Name
        </label>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <p className="relative capitalize text-xs !px-0 font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </>
        );
      },
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Type
        </label>
      ),
      dataIndex: 'uom_category',
      key: 'uom_category',
      width: '10%',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <p className="relative capitalize text-xs !px-0 font-open-sans text-tertiary-filter text-transform-capitalize">
              {text}
            </p>
          </>
        );
      },
    },
    {
      title: (
        <label className="text-sm font-bold font-open-sans text-primary-dark">
          Action
        </label>
      ),
      dataIndex: 'action',
      key: 'action',
      width: '8%',
      render: (_: any, record: any) => (
        <Space>
          <Tooltip
            title={'Edit'}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          >
            <EditOutlined
              // onClick={handleModalOpen}
              onClick={() => handleModalOpen(record)}
              className="text-xs cursor-pointer hover:opacity-75 text-tertiary-filter hover:text-primary-dark"
            />
          </Tooltip>
          <Tooltip
            title={'Delete'}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          >
            <DeleteOutlined
              className="text-xs cursor-pointer hover:opacity-75 text-tertiary-filter hover:text-primary-dark"
              onClick={() => {
                openNotification({
                  onApprove: () => handleDeleteUOM(record.key),
                  title: 'Deleting UOM',
                  subTitle: 'Are you sure you want to delete this UOM',
                  approveBtnClasses:
                    'text-white font-open-sans bg-secondary-red px-10 py-4 hover:!text-secondary-red hover:!bg-white !outline-none  border-secondary-red shadow-none active:bg-secondary-red focus:shadow-none focus:border-secondary-red focus:bg-secondary-red',
                  cancelBtnClasses: 'px-10 py-4',
                  approveText: 'Delete',
                  cancelText: 'No',
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const dispatch: AppDispatch = useDispatch();

  const transformedUomList = uomList?.map((item: any) => {
    return {
      key: item?.id,
      uomCode: item?.uom_code,
      name: item?.uom_name,
      uom_category: item?.uom_category,
      is_active: item?.is_active,
      user_id: item?.user_id,
    };
  });

  return (
    <Spin spinning={uomListLoading}>
      <Flex className="relative h-full m-2 overflow-auto sticky-footer">
        <Table
          totalPages={pagination?.total_pages}
          pagination={true}
          pageSize={PER_PAGE_ITEM}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          dataSource={transformedUomList}
        />
        <UOMModal
          isEdit={true}
          addUserLoading={addUserLoading}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          handelGetUomList={handelGetUomList}
          userDataById={userDataById}
        />
      </Flex>
    </Spin>
  );
}
