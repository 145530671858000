import { Col, Form, FormInstance, Row } from 'antd';
import { AppDispatch, RootState } from 'app/store';
import FormInput from 'common/FormInput';
import Modal from 'common/Modal';
import {
  addMaterials,
  getMaterialsList,
  setExperimentId,
} from 'features/sectionHeadFlow/experiments/redux/slice';
import { useDispatch } from 'react-redux';
import { MATERIAL_TYPES } from '..';
import { useCallback, useEffect, useState } from 'react';
import {
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
  MATERIAL_MASTERS_FIELDS,
} from 'utilities/helpers';
import RemarksModal from 'common/RemarksModal';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { getMaterial } from 'features/sectionHeadFlow/redux/slice';

type TList = {
  label: string;
  value: string;
};

type TProps = {
  isOpen: boolean;
  onClose: () => void;
  renderedUOMList: TList[];
  onUomSearch: (val: string) => void;
  projectId: string | number;
  addMaterialsLoading: boolean;
  experimentId: string | null;
  parentForm: FormInstance<any>;
  folderId: string | undefined;
  isCreate: boolean;
  isView: boolean;
  isExternalChemist: boolean;
};

export default function RawMaterialModal({
  isOpen,
  onClose,
  renderedUOMList,
  onUomSearch,
  projectId,
  addMaterialsLoading,
  experimentId,
  parentForm,
  folderId,
  isCreate,
  isView,
  isExternalChemist,
}: TProps) {
  const [form] = Form.useForm();

  const dispatch: AppDispatch = useDispatch();
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const [mol_formulaFormatted, setMol_formulaFormatted] = useState<string>(''); // Formatted input state
  const [transformedMaterialNames, setTransformedMaterialNames] = useState<
    any[]
  >([]);
  const [transformedCASNumbers, setTransformedCASNumbers] = useState<any[]>([]);
  const [transformedMolFormulas, setTransformedMolFormulas] = useState<any[]>(
    []
  );
  const [transformedMolWeights, setTransformedMolWeights] = useState<any[]>([]);
  const { getMaterialLoading, materialData } = useSelector(
    (state: RootState) => state.sectionHead
  );

  const handleFormValuesChange = (changedVal: any, values: any) => {
    if (isFinite(changedVal.material_name)) {
      form.setFieldsValue({
        cas_number: transformedCASNumbers.find(
          (item) => item.value === values.material_name
        )?.value,
        mol_formula: transformedMolFormulas.find(
          (item) => item.value === values.material_name
        )?.label,

        mol_weight: transformedMolWeights.find(
          (item) => item.value === values.material_name
        )?.label,
      });
    }

    if (isFinite(changedVal.cas_number)) {
      form.setFieldsValue({
        material_name: transformedMaterialNames.find(
          (item) => item.value === values.cas_number
        )?.value,
        mol_formula: transformedMolFormulas.find(
          (item) => item.value === values.cas_number
        )?.label,
        mol_weight: transformedMolWeights.find(
          (item) => item.value === values.cas_number
        )?.label,
      });
    }

    // if (isFinite(changedVal.mol_formula)) {
    //   form.setFieldsValue({
    //     material_name: transformedMaterialNames.find(
    //       (item) => item.value === values.mol_formula
    //     )?.value,
    //     cas_number: transformedCASNumbers.find(
    //       (item) => item.value === values.mol_formula
    //     )?.value,
    //     mol_weight: transformedMolWeights.find(
    //       (item) => item.value === values.mol_formula
    //     )?.label,
    //   });
    // }

    if (changedVal.mol_formula) {
      setMol_formulaFormatted(changedVal.mol_formula);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === ' ') {
      // form.setFieldsValue({
      //   mol_formula: convertTextWithChemicalFormulas(mol_formulaFormatted),
      // });
    }
  };

  //handle material search
  const handleSearch = (searchTerm: string, slug: string) => {
    if (searchTerm) {
      form.resetFields(MATERIAL_MASTERS_FIELDS.filter((val) => val !== slug));
      const payload = {
        material_fields: [
          {
            fields: MATERIAL_MASTERS_FIELDS.map((val) => ({
              field_name: val,
              filter: val === slug ? searchTerm : null,
            })),
          },
        ],
      };
      dispatch(getMaterial(payload));
    }
  };

  const debouncedSearch = useCallback(
    debounce((term: string, slug: string) => handleSearch(term, slug), 500),
    []
  );

  //loading master material in select fields
  useEffect(() => {
    if (materialData && !getMaterialLoading) {
      setTransformedMaterialNames(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[0]])
          .map((item) => ({ value: item.id, label: item.material_name }))
      );

      setTransformedCASNumbers(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[1]])
          .map((item) => ({ value: item.id, label: item.cas_number }))
      );

      setTransformedMolFormulas(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[2]])
          .map((item) => ({ value: item.id, label: item.mol_formula }))
      );

      setTransformedMolWeights(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[3]])
          .map((item) => ({ value: item.id, label: item.mol_weight }))
      );
    }
  }, [materialData]);

  const handleOnFinish = (values: any) => {
    setIsRemarksModalOpen(false);
    const payload = {
      ...values,
      // mol_formula: convertChemicalFormulasToSimpleText(values.mol_formula),
      material_name: transformedMaterialNames.find(
        (item) => item.value === values.material_name
      )?.label,
      cas_number: transformedCASNumbers.find(
        (item) => item.value === values.cas_number
      )?.label,
      project_id: projectId,
      folder_id: folderId,
      is_manual_entry: true,
      uom_id: values.uom_master_id,
      type: MATERIAL_TYPES.rawMaterials,
      experiment_id: experimentId || null,
      change_remarks: values.remark,
      section: 'raw_material_details',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };
    dispatch(addMaterials(payload)).then((res: any) => {
      if (res?.payload?.success) {
        const experiment_id = res?.payload?.experiment_id || experimentId;
        const listPayload = {
          project_id: projectId,
          experiment_id,
        };
        dispatch(setExperimentId(experiment_id));
        parentForm.setFieldsValue({ experiment_id: experiment_id });
        dispatch(getMaterialsList(listPayload));
        form.resetFields();
        onClose();
      } else {
        setIsRemarksModalOpen(false);
      }
    });
  };

  const handleSubmit = (remark: string) => {
    form
      .validateFields()
      .then((values: any) => {
        if (isCreate || isView) {
          handleOnFinish({ ...values, remark });
        } else {
          if (isRemarksModalOpen) {
            handleOnFinish({ ...values, remark });
          } else {
            setIsRemarksModalOpen(true);
          }
        }
      })
      .catch(() => {});
  };

  return (
    <Modal
      headerTitle="Raw Materials"
      isOpen={isOpen}
      btnLoading={addMaterialsLoading}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      onAgree={() => form.submit()}
      onCancel={() => form.resetFields()}
    >
      <Form
        id="raw_material_modal_form"
        onFinish={handleSubmit}
        onValuesChange={handleFormValuesChange}
        onKeyDown={handleKeyDown}
        form={form}
        layout="vertical"
      >
        <>
          <div className="px-3">
            <Row gutter={10}>
              <Col span={24} sm={{ span: 12 }}>
                <FormInput
                  marginBottom="medium"
                  name="material_name"
                  label={
                    <span className="text-tertiary-dark">Material Name</span>
                  }
                  required
                  validateMsg="Material Name is required"
                  defaultStyle={false}
                  placeholder="Enter material name"
                  type="select"
                  selectInputClasses="border-none focus-within:border-none  placeholder:text-primary"
                  wrapperClasses={`rounded-md !m-0 !w-full !p-0 hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark`}
                  onSearch={(t) => debouncedSearch(t, 'material_name')}
                  loading={getMaterialLoading}
                  options={transformedMaterialNames}
                  optionRender={(option) => (
                    <p className="text-xs">{option.label}</p>
                  )}
                />
              </Col>
              <Col span={24} sm={{ span: 12 }}>
                <FormInput
                  marginBottom="medium"
                  name="cas_number"
                  label={<span className="text-tertiary-dark">CAS Number</span>}
                  defaultStyle={false}
                  placeholder="Enter CAS number"
                  type="select"
                  selectInputClasses="border-none focus-within:border-none  placeholder:text-primary"
                  wrapperClasses={`rounded-md !m-0 !w-full !p-0 hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark`}
                  loading={getMaterialLoading}
                  onSearch={(t) => debouncedSearch(t, 'cas_number')}
                  options={transformedCASNumbers}
                  optionRender={(option) => (
                    <p className="text-xs">{option.label}</p>
                  )}
                />
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24} sm={{ span: 8 }}>
                <FormInput
                  marginBottom="medium"
                  name="mol_formula"
                  label={
                    <span className="text-tertiary-dark">Mol. Formula</span>
                  }
                  defaultStyle={false}
                  placeholder="Enter mol. formula"
                  disabled
                />
              </Col>
              <Col span={24} sm={{ span: 8 }}>
                <FormInput
                  marginBottom="medium"
                  name="mol_weight"
                  label={<span className="text-tertiary-dark">Mol. Wt</span>}
                  defaultStyle={false}
                  type="number"
                  placeholder="Enter mol. wt"
                  disabled
                />
              </Col>
              <Col span={24} sm={{ span: 8 }}>
                <Row>
                  <Col span={16}>
                    <FormInput
                      marginBottom="medium"
                      name="quantity"
                      type="number"
                      label={
                        <span className="text-tertiary-dark">Quantity</span>
                      }
                      inputClasses="p-select font-open-sans shadow-none border focus-within:border-primary-dark hover:border-tertiary-highlight-200 rounded-e-none"
                      defaultStyle={false}
                      placeholder="Enter quantity"
                    />
                  </Col>
                  <Col span={8}>
                    <FormInput
                      popupClassName="select-dropdown"
                      name="uom_master_id"
                      label={<div className="invisible">uom</div>}
                      onSearch={onUomSearch}
                      type="select"
                      defaultStyle={false}
                      placeholder="UOM"
                      options={renderedUOMList}
                      selectInputClasses="selected-item-transform border-none focus-within:border-none  placeholder:text-primary select-placeholder"
                      wrapperClasses="rounded-e-md hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24} sm={{ span: 8 }}>
                <FormInput
                  marginBottom="medium"
                  name="moles"
                  label={<span className="text-tertiary-dark">Moles</span>}
                  defaultStyle={false}
                  placeholder="moles"
                  type="number"
                />
              </Col>
              <Col span={24} sm={{ span: 8 }}>
                <FormInput
                  marginBottom="medium"
                  name="equivalents"
                  label={
                    <span className="text-tertiary-dark">Equivalents</span>
                  }
                  type="number"
                  defaultStyle={false}
                  placeholder="Enter equivalents"
                />
              </Col>
              <Col span={24} sm={{ span: 8 }}>
                <FormInput
                  marginBottom="medium"
                  name="source"
                  label={<span className="text-tertiary-dark">Source</span>}
                  defaultStyle={false}
                  placeholder="Enter source"
                />
              </Col>
            </Row>
          </div>
          {/*change remarks modal */}
          <RemarksModal
            isOpen={isRemarksModalOpen && isOpen}
            onApprove={(value) => handleSubmit(value)}
            loading={addMaterialsLoading}
            onClose={() => setIsRemarksModalOpen(false)}
          />
        </>
      </Form>
    </Modal>
  );
}
