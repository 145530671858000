import { Button, Col, Flex, Form, Row, FormInstance } from 'antd';
import FormInput from 'common/FormInput';
import RawMaterialModal from '../Modals/RawMaterialModal';
import { useCallback, useEffect, useState } from 'react';
import { getUomList } from 'features/proposalLeadFlow/projects/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import { debounce } from 'lodash';
import {
  addMaterials,
  getMaterialsList,
  setExperimentId,
} from '../../../redux/slice';
import { MATERIAL_TYPES } from '..';
import {
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
  MATERIAL_MASTERS_FIELDS,
} from 'utilities/helpers';
import RemarksModal from 'common/RemarksModal';
import { getMaterial } from 'features/sectionHeadFlow/redux/slice';

// type TProps = {
//   form: FormInstance<any>;
//   projectId: string | number;
//   isCreate: boolean;
//   isView: boolean;
//   isEdit: boolean;
// };

type TProps = {
  projectId: string | number;
  isCreate: boolean;
  isView: boolean;
  listLength: number;
  parentForm: FormInstance<any>;
  folderId: string | undefined;
};

export default function RawMaterialTab({
  // form,
  projectId,
  listLength,
  isCreate,
  isView,
  parentForm,
  folderId,
}: TProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const [form] = Form.useForm();

  const { uomList } = useSelector((state: RootState) => state.project2);
  const { addMaterialsLoading, experimentId, isExternalChemist } = useSelector(
    (state: RootState) => state.experiments
  );
  const { getMaterialLoading, materialData } = useSelector(
    (state: RootState) => state.sectionHead
  );
  const [transformedMaterialNames, setTransformedMaterialNames] = useState<
    any[]
  >([]);
  const [transformedCASNumbers, setTransformedCASNumbers] = useState<any[]>([]);
  const [transformedMolFormulas, setTransformedMolFormulas] = useState<any[]>(
    []
  );
  const [transformedMolWeights, setTransformedMolWeights] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [mol_formulaInput, setMol_formulaInput] = useState<string>(''); // Formatted input state

  const dispatch: AppDispatch = useDispatch();

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleFormValuesChange = (changedVal: any, values: any) => {
    if (isFinite(changedVal.material_name)) {
      form.setFieldsValue({
        cas_number: transformedCASNumbers.find(
          (item) => item.value === values.material_name
        )?.value,
        mol_formula: transformedMolFormulas.find(
          (item) => item.value === values.material_name
        )?.label,

        mol_weight: transformedMolWeights.find(
          (item) => item.value === values.material_name
        )?.label,
      });
    }

    if (isFinite(changedVal.cas_number)) {
      form.setFieldsValue({
        material_name: transformedMaterialNames.find(
          (item) => item.value === values.cas_number
        )?.value,
        mol_formula: transformedMolFormulas.find(
          (item) => item.value === values.cas_number
        )?.label,
        mol_weight: transformedMolWeights.find(
          (item) => item.value === values.cas_number
        )?.label,
      });
    }

    if (changedVal.mol_formula) {
      setMol_formulaInput(changedVal.mol_formula);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === ' ') {
      // Check for spacebar key
      // form.setFieldsValue({
      //   mol_formula: convertTextWithChemicalFormulas(mol_formulaInput),
      // });
    }
  };

  const finalSubmit = (values: any) => {
    setMol_formulaInput('');
    const payload = {
      ...values,
      // mol_formula: convertChemicalFormulasToSimpleText(values?.mol_formula),
      material_name: transformedMaterialNames.find(
        (item) => item.value === values.material_name
      )?.label,
      cas_number: transformedCASNumbers.find(
        (item) => item.value === values.cas_number
      )?.label,
      project_id: projectId,
      folder_id: folderId,
      is_manual_entry: false,
      uom_id: values.uom_master_id,
      type: MATERIAL_TYPES.rawMaterials,
      experiment_id: experimentId || null,
      change_remarks: values.remark,
      section: 'raw_material_details',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };

    dispatch(addMaterials(payload)).then((res: any) => {
      setIsRemarksModalOpen(false);
      if (res?.payload?.success) {
        const experiment_id = res?.payload?.experiment_id || experimentId;
        const listPayload = {
          project_id: projectId,
          experiment_id,
        };
        dispatch(setExperimentId(experiment_id));
        parentForm.setFieldsValue({ experiment_id: experiment_id });
        form.resetFields();
        dispatch(getMaterialsList(listPayload));
      }
    });
  };

  //handle material search
  const handleSearch = (searchTerm: string, slug: string) => {
    if (searchTerm) {
      form.resetFields(MATERIAL_MASTERS_FIELDS.filter((val) => val !== slug));
      const payload = {
        material_fields: [
          {
            fields: MATERIAL_MASTERS_FIELDS.map((val) => ({
              field_name: val,
              filter: val === slug ? searchTerm : null,
            })),
          },
        ],
      };
      dispatch(getMaterial(payload));
    }
  };

  const debouncedSearch = useCallback(
    debounce((term: string, slug: string) => handleSearch(term, slug), 500),
    []
  );

  //loading master material in select fields
  useEffect(() => {
    if (materialData && !getMaterialLoading) {
      setTransformedMaterialNames(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[0]])
          .map((item) => ({ value: item.id, label: item.material_name }))
      );

      setTransformedCASNumbers(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[1]])
          .map((item) => ({ value: item.id, label: item.cas_number }))
      );

      setTransformedMolFormulas(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[2]])
          .map((item) => ({ value: item.id, label: item.mol_formula }))
      );

      setTransformedMolWeights(
        materialData
          .filter((item) => item[MATERIAL_MASTERS_FIELDS[3]])
          .map((item) => ({ value: item.id, label: item.mol_weight }))
      );
    }
  }, [materialData]);

  const handleSubmit = (remark: string) => {
    form
      .validateFields()
      .then((values: any) => {
        if (isCreate || isView) {
          finalSubmit({ ...values, remark });
        } else {
          if (isRemarksModalOpen) {
            finalSubmit({ ...values, remark });
          } else {
            setIsRemarksModalOpen(true);
          }
        }
      })
      .catch(() => {});
  };

  const renderedUOMList = uomList?.map((uom) => {
    return { label: uom.uom_code, value: uom.id };
  });

  useEffect(() => {
    const payloadUOM = {
      search: searchTerm,
    };
    dispatch(getUomList(payloadUOM));
  }, [searchTerm]);

  const handleUomSearch = useCallback(
    debounce((val: string) => {
      setSearchTerm(val);
    }, 500),
    []
  );

  const onUomSearch = (val: string) => {
    handleUomSearch(val);
  };

  return (
    <Form
      id="raw-material-form"
      onFinish={handleSubmit}
      onValuesChange={handleFormValuesChange}
      onKeyDown={handleKeyDown}
      form={form}
      layout="vertical"
    >
      <Row gutter={10}>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <FormInput
            name="material_name"
            label={<span className="text-tertiary-dark">Material Name</span>}
            required
            validateMsg="Material Name is required"
            defaultStyle={false}
            placeholder="Select material name"
            type="select"
            selectInputClasses="border-none focus-within:border-none  placeholder:text-primary"
            wrapperClasses={`rounded-md !m-0 !p-0 hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark`}
            onSearch={(t) => debouncedSearch(t, 'material_name')}
            loading={getMaterialLoading}
            options={transformedMaterialNames}
            optionRender={(option) => <p className="text-xs">{option.label}</p>}
          />
        </Col>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <FormInput
            name="cas_number"
            label={<span className="text-tertiary-dark">CAS Number</span>}
            defaultStyle={false}
            placeholder="Select CAS number"
            type="select"
            selectInputClasses="border-none focus-within:border-none  placeholder:text-primary"
            wrapperClasses={`rounded-md !m-0 !p-0 hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark`}
            loading={getMaterialLoading}
            onSearch={(t) => debouncedSearch(t, 'cas_number')}
            options={transformedCASNumbers}
            optionRender={(option) => <p className="text-xs">{option.label}</p>}
          />
        </Col>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <FormInput
            name="mol_formula"
            label={<span className="text-tertiary-dark">Mol. Formula</span>}
            defaultStyle={false}
            placeholder="Enter mol. formula"
            required
            validateMsg="Mol. formula is required"
            disabled
          />
        </Col>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <FormInput
            name="mol_weight"
            type="number"
            label={<span className="text-tertiary-dark">Mol. Wt</span>}
            defaultStyle={false}
            placeholder="Enter mol. wt"
            required
            validateMsg="Mol. wt is required"
            disabled
          />
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <Row>
            <Col span={16}>
              <FormInput
                name="quantity"
                type="number"
                label={<span className="text-tertiary-dark">Quantity</span>}
                required={listLength ? false : true}
                inputClasses="p-select font-open-sans selected-item-transform shadow-none border focus-within:border-primary-dark hover:border-tertiary-highlight-200 rounded-e-none"
                validateMsg="Quantity is required"
                defaultStyle={false}
                placeholder="Enter quantity"
                disabled={listLength ? true : false}
              />
            </Col>
            <Col span={8}>
              <FormInput
                popupClassName="select-dropdown"
                name="uom_master_id"
                label={<div className="invisible">uom</div>}
                onSearch={onUomSearch}
                type="select"
                defaultStyle={false}
                placeholder="UOM"
                options={renderedUOMList}
                hideColon
                required
                validateMsg={
                  <span className="text-xs sm:text-2xs">UOM is required</span>
                }
                selectInputClasses="selected-item-transform border-none focus-within:border-none  placeholder:text-primary select-placeholder"
                wrapperClasses={`rounded-e-md !m-0 !p-0 hover:border-tertiary-highlight-200 border-tertiary-highlight-200 border focus-within:border-primary-dark`}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <FormInput
            name="moles"
            label={<span className="text-tertiary-dark">Moles</span>}
            defaultStyle={false}
            placeholder="moles"
            disabled
          />
        </Col>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <FormInput
            name="equivalents"
            label={<span className="text-tertiary-dark">Equivalents</span>}
            defaultStyle={false}
            placeholder="Enter equivalents"
            type="number"
            required
            validateMsg="Equivalents is required"
          />
        </Col>
        <Col span={24} sm={{ span: 12 }} md={{ span: 6 }}>
          <FormInput
            name="source"
            label={<span className="text-tertiary-dark">Source</span>}
            defaultStyle={false}
            placeholder="Enter source"
          />
        </Col>
      </Row>
      <Flex wrap gap={10} justify="center" className="m-3">
        <>
          <Button
            onClick={() => form.resetFields()}
            className="text-sm font-open-sans hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
          >
            Clear
          </Button>
          <Button
            type="primary"
            className="text-sm font-semibold bg-transparent border font-open-sans sm:w-auto text-primary border-primary hover:text-white hover:!bg-primary"
            onClick={() => handleSubmit('')}
            loading={addMaterialsLoading}
          >
            Add Values
          </Button>
          <Button
            type="primary"
            className="text-sm font-semibold bg-transparent border sm:w-auto  text-primary border-primary hover:text-white hover:!bg-primary font-open-sans"
            onClick={handleModalOpen}
          >
            Add manual entries
          </Button>
        </>
      </Flex>

      {/* raw material modal for manual entries */}
      <RawMaterialModal
        isExternalChemist={isExternalChemist as boolean}
        parentForm={parentForm}
        addMaterialsLoading={addMaterialsLoading}
        isOpen={isModalOpen}
        projectId={projectId}
        folderId={folderId}
        renderedUOMList={renderedUOMList}
        onUomSearch={onUomSearch}
        onClose={handleModalClose}
        experimentId={experimentId}
        isCreate={isCreate}
        isView={isView}
      />

      {/*change remarks modal */}
      <RemarksModal
        isOpen={isRemarksModalOpen}
        onApprove={(value) => handleSubmit(value)}
        loading={addMaterialsLoading}
        onClose={() => setIsRemarksModalOpen(false)}
      />
    </Form>
  );
}
