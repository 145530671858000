import Card from 'common/Card';
import Observation from './Observation';
import ProcedureTable from 'common/ProcedureTable';
import { Button, Divider, Empty, Flex, FormInstance, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import {
  addProcedure,
  generateParaphrase,
  getProdecedureList,
  setExperimentId,
} from '../redux/slice';
import { useEffect, useState } from 'react';
import { getLoggedInUser, SECTION, TYPE } from 'utilities/helpers';
import ai from 'assets/newIcon/ai-chipset.svg';
import SectionHeader from 'common/SectionHeader';
import {
  convertTextWithChemicalFormulas,
  convertChemicalFormulasToSimpleText,
} from 'utilities/helpers';
import { useSelector } from 'react-redux';
import { openNotification } from 'utilities/notification';

type TProcedureList = {
  id: string | number;
  is_active: boolean;
  experiment_details: string;
  procedure: string;
  type: string;
  procedure_time: string;
};

type TList = {
  id: string | number;
  stepNo: number;
  experiment: string;
  observation: string;
  type: string;
  is_Active: boolean;
  procedure_time: string;
};

type TProps = {
  form: FormInstance<any>;
  projectId: string | number;
  procedureList: TProcedureList[];
  setStepNos: React.Dispatch<React.SetStateAction<number>>;
  isCreate: boolean;
  isView: boolean;
  experimentId: string | null;
  folderId: string | undefined;
  experimentStatus: string;
  unsavedSections: string[];
  setUnsavedSections: React.Dispatch<React.SetStateAction<string[]>>;
};

const getGroupedProcedures = (data: any[]) => {
  const reactions = data.filter((item: any) => item.type === TYPE.reactions);
  const workUps = data.filter((item: any) => item.type === TYPE.work_up);
  const isolations = data.filter((item: any) => item.type === TYPE.isolation);

  return { reactions, workUps, isolations };
};

export default function Procedure({
  form,
  projectId,
  procedureList,
  setStepNos,
  isCreate,
  isView,
  experimentId,
  folderId,
  experimentStatus,
  unsavedSections,
  setUnsavedSections,
}: TProps) {
  const { role } = getLoggedInUser();
  const [reactionsList, setReactionsList] = useState<TList[]>([]);
  const [workUpList, setWorkUpList] = useState<TList[]>([]);
  const [isolationList, setIsolationList] = useState<TList[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const { isExternalChemist, paraphraseStepsLoading, procedureListLoading } =
    useSelector((state: RootState) => state.experiments);
  const [paraphrasedIds, setParaphrasedIds] = useState<(number | string)[]>([]);
  const [isDisabled, setIsDisabled] = useState<number[]>([]);
  //tranfoeming incomuing procedure list
  const transformProcedureList = (
    procedureType: string,
    checkForParaphased: boolean = true
  ): TList[] => {
    return procedureList
      .filter((reaction) => reaction.type === procedureType)
      .map((reaction: TProcedureList, i): TList => {
        const { isParaphrased, foundReaction } =
          checkExistingEntry(procedureType);

        if (isParaphrased && checkForParaphased) {
          return {
            id: reaction.id,
            is_Active: reaction.is_active,
            experiment: foundReaction?.experiment as string,
            observation: foundReaction?.observation as string,
            // experiment: convertTextWithChemicalFormulas(
            //   foundReaction?.experiment as string
            // ),
            // observation: convertTextWithChemicalFormulas(
            //   foundReaction?.observation as string
            // ),
            procedure_time: foundReaction?.procedure_time as string,
            stepNo: i + 1,
            type: reaction.type,
          };
        } else {
          return {
            id: reaction.id,
            is_Active: reaction.is_active,
            experiment: reaction.experiment_details,
            observation: reaction.procedure,
            // experiment: convertTextWithChemicalFormulas(
            //   reaction.experiment_details
            // ),
            // observation: convertTextWithChemicalFormulas(reaction.procedure),
            procedure_time: reaction?.procedure_time as string,
            stepNo: i + 1,
            type: reaction.type,
          };
        }
      });
  };

  //discard unsaved procedure which were paraphrased
  const discardUnsavedProcedure = () => {
    dispatch(
      getProdecedureList({
        project_id: projectId,
        experiment_id: experimentId,
      })
    ).then((res: any) => {
      if (res?.payload?.success) {
        const reactions = transformProcedureList(TYPE.reactions, false);
        const workUp = transformProcedureList(TYPE.work_up, false);
        const isolation = transformProcedureList(TYPE.isolation, false);
        setReactionsList(reactions);
        setWorkUpList(workUp);
        setIsolationList(isolation);
        setIsEditing(false);
        setUnsavedSections(
          unsavedSections.filter((sec) => sec !== SECTION.procedure)
        );
        setParaphrasedIds([]);
      }
    });
  };

  const handleSave = () => {
    if (isEditing) {
      if (paraphrasedIds.length > 0) {
        openNotification({
          onCancel: () => null,
          onApprove: () => discardUnsavedProcedure(),
          okBtnLoading: procedureListLoading,
          title: 'Please confirm',
          subTitle: (
            <span className="text-sm font-open-sans">
              {(() => {
                const unsavedData = [
                  ...reactionsList,
                  ...workUpList,
                  ...isolationList,
                ]
                  .filter((item: any) => paraphrasedIds.includes(item.id))
                  .map((item: any) => item.stepNo);

                return `Step ${unsavedData} are not saved, please save them or discard unsaved changes.`;
              })()}
            </span>
          ),
          approveBtnClasses:
            'text-white font-open-sans bg-secondary-red px-10 py-4 hover:!text-secondary-red hover:!bg-white !outline-none  border-secondary-red shadow-none active:bg-secondary-red focus:shadow-none focus:border-secondary-red focus:bg-secondary-red',
          cancelBtnClasses: 'px-10 py-4',
          approveText: 'Discard',
          cancelText: 'Cancel',
        });
      } else {
        setIsEditing(false);
        setUnsavedSections(
          unsavedSections.filter((sec) => sec !== SECTION.procedure)
        );
      }
    } else {
      setIsEditing(true);
      setUnsavedSections([...unsavedSections, SECTION.procedure]);
    }
  };

  const handleAddingProcedure = (type: string, remark: string) => {
    const values = form.getFieldsValue();

    const experiment = `experiment_${type}`;
    const observation = `observation_${type}`;
    const procedure_time = `procedure_time_${type}`;
    const payload = {
      // experiment_details: convertChemicalFormulasToSimpleText(
      //   values[experiment]
      // ),
      // procedure: convertChemicalFormulasToSimpleText(values[observation]),
      experiment_details: values[experiment],
      procedure: values[observation],
      procedure_time: values[procedure_time],
      project_id: projectId,
      type,
      experiment_id: experimentId || null,
      folder_id: folderId,
      change_remarks: remark,
      section: 'procedure',
      is_edit: isView && isExternalChemist ? false : isView ? true : false,
    };
    dispatch(addProcedure(payload)).then((res: any) => {
      if (res?.payload?.success) {
        const experiment_id = res?.payload?.experiment_id || experimentId;
        const listPayload = {
          project_id: projectId,
          experiment_id,
        };
        dispatch(setExperimentId(experiment_id));
        form.setFieldsValue({
          [experiment]: null,
          [observation]: null,
          [procedure_time]: null,
          experiment_id: experiment_id,
        });
        dispatch(getProdecedureList(listPayload));
      }
    });
  };

  const checkExistingEntry = (type: string) => {
    if (type === TYPE.reactions) {
      const foundReaction = reactionsList.find((item: any) =>
        paraphrasedIds.includes(item.id)
      );
      if (foundReaction) {
        return { isParaphrased: true, foundReaction };
      } else {
        return { isParaphrased: false, foundReaction: null };
      }
    } else if (type === TYPE.work_up) {
      const foundReaction = workUpList.find((item: any) =>
        paraphrasedIds.includes(item.id)
      );
      if (foundReaction) {
        return { isParaphrased: true, foundReaction };
      } else {
        return { isParaphrased: false, foundReaction: null };
      }
    } else if (type === TYPE.isolation) {
      const foundReaction = isolationList.find((item: any) =>
        paraphrasedIds.includes(item.id)
      );
      if (foundReaction) {
        return { isParaphrased: true, foundReaction };
      } else {
        return { isParaphrased: false, foundReaction: null };
      }
    } else {
      return { isParaphrased: false, foundReaction: null };
    }
  };

  useEffect(() => {
    setStepNos(reactionsList.length + workUpList.length + isolationList.length);
  }, [reactionsList, workUpList, isolationList]);

  useEffect(() => {
    const reactions = transformProcedureList(TYPE.reactions);
    const workUp = transformProcedureList(TYPE.work_up);
    const isolation = transformProcedureList(TYPE.isolation);
    setReactionsList(reactions);
    setWorkUpList(workUp);
    setIsolationList(isolation);
  }, [procedureList]);

  //updating the the procedures with paraphrased texts
  const updateProcedureList = (existingList: any[], newData: any[]) => {
    return existingList.map((item: any) => {
      const foundItem = newData.find((r: any) => r.id === item.id);
      if (foundItem && item.id === foundItem.id) {
        return {
          ...item,
          experiment: foundItem.experiment_text,
          observation: foundItem.observation_text,
        };
      } else {
        return item;
      }
    });
  };

  //handle click on paraphrase button
  const onParaphraseClick = () => {
    const tranformedList = procedureList?.map((exp: any) => ({
      id: exp.id,
      type: exp.type,
      experiment_text: exp.experiment_details,
      observation_text: exp.procedure,
    }));
    const payload = {
      steps: tranformedList,
    };
    dispatch(generateParaphrase(payload)).then((res: any) => {
      if (res?.payload?.success) {
        const data = res?.payload?.results;
        //setting ids to if the field is paraphrased
        const ids = data.map((item: any) => item.id);
        setParaphrasedIds(ids);
        const { reactions, workUps, isolations } = getGroupedProcedures(data);
        setReactionsList((oldList) => {
          return updateProcedureList(oldList, reactions);
        });
        setWorkUpList((oldList) => {
          return updateProcedureList(oldList, workUps);
        });
        setIsolationList((oldList) => {
          return updateProcedureList(oldList, isolations);
        });
      }
    });
  };

  return (
    <Card
      header={
        <SectionHeader
          disabled={
            Boolean(isDisabled.length) || Boolean(paraphrasedIds.length)
          }
          isEditing={isEditing}
          isView={isView}
          onSave={handleSave}
          experimentStatus={experimentStatus}
          title="Procedure"
          experimentId={experimentId}
          onParaphraseClick={onParaphraseClick}
          paraphraseLoading={paraphraseStepsLoading}
          disableParaphrase={!procedureList.length || !isEditing}
          customHeader={
            isCreate ? (
              <Flex justify="space-between" align="center">
                <label className="text-sm sm:text-base">Procedure</label>
                <Button
                  className={`bg-transparent hidden md:inline-flex ${procedureList.length && 'hover:!text-white hover:!bg-primary'} !px-2 font-open-sans border-primary text-primary`}
                  icon={<img alt="ai logo" src={ai} className="w-5" />}
                  onClick={onParaphraseClick}
                  loading={paraphraseStepsLoading}
                  disabled={!procedureList.length}
                >
                  Paraphrase
                </Button>
              </Flex>
            ) : null
          }
        />
      }
    >
      <div className="flex flex-col gap-6 p-6">
        <Card
          header={'Reactions'}
          headerClasses="font-semibold text-tertiary-dark"
        >
          <>
            {(isCreate || (isView && isEditing)) && (
              <ProcedureTable
                onAdd={handleAddingProcedure}
                type={TYPE.reactions}
                isCreate={isCreate}
                isView={isView}
              />
            )}
            {isView && !reactionsList.length && <Empty className="!my-2" />}

            {reactionsList.map((reaction, i) => {
              return (
                <>
                  <Observation
                    isCreate={isCreate}
                    type={TYPE.reactions}
                    isView={isView}
                    isEditing={isEditing}
                    projectId={projectId}
                    key={reaction.id}
                    stepNo={reaction.stepNo}
                    id={reaction.id}
                    experiment={reaction.experiment}
                    observation={reaction.observation}
                    procedureTime={reaction.procedure_time}
                    isExternalChemist={isExternalChemist as boolean}
                    paraphrasedIds={paraphrasedIds}
                    setParaphrasedIds={setParaphrasedIds}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                  />
                  <Divider className="my-2" />
                </>
              );
            })}
          </>
        </Card>
        <Card
          header={'Work-up'}
          headerClasses="font-semibold text-tertiary-dark"
        >
          <>
            {(isCreate || (isView && isEditing)) && (
              <ProcedureTable
                onAdd={handleAddingProcedure}
                type={TYPE.work_up}
                isCreate={isCreate}
                isView={isView}
              />
            )}
            {isView && !workUpList.length && <Empty className="!my-2" />}

            {workUpList.map((work_up, i) => {
              return (
                <>
                  <Observation
                    type={TYPE.work_up}
                    isCreate={isCreate}
                    isView={isView}
                    isEditing={isEditing}
                    projectId={projectId}
                    key={work_up.id}
                    stepNo={reactionsList.length + work_up.stepNo}
                    id={work_up.id}
                    experiment={work_up.experiment}
                    observation={work_up.observation}
                    procedureTime={work_up.procedure_time}
                    isExternalChemist={isExternalChemist as boolean}
                    paraphrasedIds={paraphrasedIds}
                    setParaphrasedIds={setParaphrasedIds}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                  />
                  <Divider className="my-2" />
                </>
              );
            })}
          </>
        </Card>
        <Card
          header={'Isolation'}
          headerClasses="font-semibold text-tertiary-dark"
        >
          <>
            {(isCreate || (isView && isEditing)) && (
              <ProcedureTable
                onAdd={handleAddingProcedure}
                type={TYPE.isolation}
                isCreate={isCreate}
                isView={isView}
              />
            )}
            {isView && !isolationList.length && <Empty className="!my-2" />}
            {isolationList.map((iso, i) => {
              return (
                <>
                  <Observation
                    type={TYPE.isolation}
                    isEditing={isEditing}
                    isCreate={isCreate}
                    isView={isView}
                    projectId={projectId}
                    key={iso.id}
                    stepNo={
                      reactionsList.length + workUpList.length + iso.stepNo
                    }
                    id={iso.id}
                    experiment={iso.experiment}
                    observation={iso.observation}
                    procedureTime={iso.procedure_time}
                    isExternalChemist={isExternalChemist as boolean}
                    paraphrasedIds={paraphrasedIds}
                    setParaphrasedIds={setParaphrasedIds}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                  />
                  <Divider className="my-2" />
                </>
              );
            })}
          </>
        </Card>
      </div>
    </Card>
  );
}
