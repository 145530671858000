import apiClient from 'utilities/apiClient';
interface ResponseCommon {
  success: boolean;
  message: string;
}
interface ResponseWithData {
  success: boolean;
  message: string;
  data: any;
}
interface ResponseWithPagination {
  success: boolean;
  message: string;
  data: any;
  pagination: any;
}

export const folderCreate = (payload: {
  [key: string]: any;
}): Promise<ResponseCommon> => {
  return apiClient.post(apiClient.Urls.createFolder, payload, true, 'file');
};

export const folderList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithPagination> => {
  return apiClient.get(apiClient.Urls.folderList, payload, true);
};

export const folderRouteList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.folderRouteList, payload, true);
};

export const userList = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.userList, payload, true);
};

export const folderDetails = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.folderDetails, payload, true);
};

export const materialGet = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.post(apiClient.Urls.getMaterial, payload, true);
};

export const materialGetInit = (payload: {
  [key: string]: any;
}): Promise<ResponseWithData> => {
  return apiClient.get(apiClient.Urls.getInitMaterial, payload, true);
};
