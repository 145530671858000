// import React, { useState, useEffect } from 'react';
// import {
//   Upload,
//   message,
//   Input,
//   Row,
//   Col,
//   Form,
//   Tag,
//   Space,
//   Divider,
//   Select,
// } from 'antd';
// import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
// import type { UploadFile, UploadProps } from 'antd';
// import './viewUploadedImageStyles.css';

// const { TextArea } = Input;
// const { Dragger } = Upload;
// const { Option } = Select;

// interface UploadImageFilesProps {
//   setSteps: (steps: any) => void;
//   steps: any;
// }

// export interface StepUpload {
//   id: number;
//   smiles_string: string;
//   imageFilesList: UploadFile[];
//   description: string;
// }

// export interface UploadedImageProps {
//   stepsData: any;
// }

// // const stepsData: StepUpload[] = [
// //   {
// //     id: 1,
// //     smiles_string: 'C1=CC=CC=C1',
// //     imageFilesList: [
// //       {
// //         uid: 'rc-upload-1734666948284-8',
// //         name: 'Lion1.png',
// //         size: 818565,
// //         type: '',
// //         status: 'done',
// //         url: '',
// //       },
// //     ],
// //     description: 'test1',
// //   },
// //   {
// //     id: 2,
// //     smiles_string: 'C1C=CC=CC=1.C1C=CC=CC=1',
// //     imageFilesList: [
// //       {
// //         uid: 'rc-upload-1734666948284-11',
// //         name: 'Sarhul-Painting1.webp',
// //         size: 125308,
// //         type: '',
// //         status: 'done',
// //         url: '',
// //       },
// //     ],
// //     description: 'test2',
// //   },
// // ];

// const ViewUploadedImage = ({ stepsData }: UploadedImageProps) => {
//   const [steps, setSteps] = useState<StepUpload[]>([]);
//   const [selectedStepId, setSelectedStepId] = useState<number>(1);

//   useEffect(() => {
//     // Initialize steps with stepsData mapped to StepUpload format
//     const formattedSteps = stepsData?.map((step: any) => ({
//       id: step.id,
//       smiles_string: step.smiles_string || '',
//       imageFilesList: step.image_obj
//         ? [
//             {
//               uid: step.id.toString(),
//               name: step.image_name || `Image_${step.id}`,
//               url: step.image_obj,
//               status: 'done' as 'done',
//             },
//           ]
//         : [],
//       description: step.description || '',
//     }));
//     setSteps(formattedSteps);
//   }, [stepsData]);

// //   useEffect(() => {
// //     // Initialize steps with stepsData
// //     setSteps(stepsData);
// //   }, [stepsData]);

//   const handleAddStep = () => {
//     const newStep: StepUpload = {
//       id: steps.length + 1,
//       smiles_string: '',
//       imageFilesList: [],
//       description: '',
//     };
//     setSteps((prevSteps) => [...prevSteps, newStep]);
//   };

//   const handleDeleteStep = (stepId: number) => {
//     setSteps((prevSteps) => prevSteps.filter((step) => step.id !== stepId));
//     if (selectedStepId === stepId && steps.length > 1) {
//       setSelectedStepId(steps[0].id); // Reset to first step if the selected step is deleted
//     }
//   };

//   const handleChangeImageFiles = (stepId: number, fileList: UploadFile[]) => {
//     const updatedFileList = fileList.slice(-1).map((file) => ({
//       ...file,
//       status: 'done' as 'done',
//     }));

//     setSteps((prevSteps) =>
//       prevSteps.map((step) =>
//         step.id === stepId ? { ...step, imageFilesList: updatedFileList } : step
//       )
//     );
//   };

//   const handlePreviewImageFiles = async (file: UploadFile) => {
//     let src = file.url as string;
//     if (!src) {
//       src = await new Promise((resolve) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file.originFileObj as File);
//         reader.onload = () => resolve(reader.result as string);
//       });
//     }
//     const image = new Image();
//     image.src = src;
//     const imgWindow = window.open(src);
//     imgWindow?.document.write(image.outerHTML);
//   };

//   const beforeUploadImageFiles = (file: File) => {
//     const isImage = file.type.startsWith('image/');
//     if (!isImage) {
//       message.error('You can only upload image files!');
//       return Upload.LIST_IGNORE;
//     }
//     return true;
//   };

//   const draggerProps: UploadProps = {
//     name: 'file',
//     multiple: false,
//     beforeUpload: beforeUploadImageFiles,
//     onPreview: handlePreviewImageFiles,
//   };

//   const selectedStep = steps.find((step) => step.id === selectedStepId);

//   return (
//     <div>
//       <Form.Item label="Select Step" style={{ marginBottom: '24px' }}>
//         <Select
//           value={selectedStepId}
//           onChange={(value) => setSelectedStepId(value)}
//           style={{ width: '100%' }}
//         >
//           {steps.map((step) => (
//             <Option key={step.id} value={step.id}>
//               Step {step.id}
//             </Option>
//           ))}
//         </Select>
//       </Form.Item>

//       {selectedStep && (
//         <div
//           key={selectedStep.id}
//           style={{
//             marginBottom: '24px',
//             border: '1px solid #ddd',
//             padding: '16px',
//             position: 'relative',
//           }}
//         >
//           <Space direction="vertical" size={10} style={{ width: '100%' }}>
//             <div>
//               <Tag
//                 style={{
//                   backgroundColor: '#0044D3',
//                   color: 'white',
//                   marginRight: '10px',
//                   fontWeight: 600,
//                   textAlign: 'center',
//                 }}
//               >
//                 Step {selectedStep.id}
//               </Tag>
//               {steps.length > 1 && (
//                 <DeleteOutlined
//                   style={{
//                     color: 'red',
//                     fontSize: '16px',
//                     cursor: 'pointer',
//                     position: 'absolute',
//                     top: '16px',
//                     right: '16px',
//                   }}
//                   onClick={() => handleDeleteStep(selectedStep.id)}
//                 />
//               )}
//             </div>
//             <Row>
//               <Col span={11} className="upload_container">
//                 <Form.Item label="Upload Image" className="field_style">
//                   <Dragger
//                     {...draggerProps}
//                     fileList={selectedStep.imageFilesList}
//                     onChange={({ fileList }) => {
//                       const singleFile = fileList.slice(-1);
//                       handleChangeImageFiles(selectedStep.id, singleFile);
//                     }}
//                     className="custom-dragger RICHA"
//                   >
//                     <p className="ant-upload-drag-icon custom-icon">
//                       <UploadOutlined />
//                     </p>
//                     <p className="ant-upload-text">
//                       Click or drag image file to upload
//                     </p>
//                   </Dragger>
//                 </Form.Item>
//               </Col>
//               <Col
//                 span={2}
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//               >
//                 <Divider type="vertical" style={{ height: '100%' }} />
//               </Col>
//               <Col span={11}>
//                 <Form.Item
//                   label="SMILES string"
//                   className="field_style field_margin"
//                 >
//                   <Input
//                     className="custom-placeholder"
//                     placeholder="Enter SMILES string"
//                     size="large"
//                     value={selectedStep.smiles_string}
//                     onChange={(e) =>
//                       setSteps((prevSteps) =>
//                         prevSteps.map((s) =>
//                           s.id === selectedStep.id
//                             ? { ...s, smiles_string: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//                 <Form.Item label="Description" className="field_style">
//                   <TextArea
//                     className="custom-placeholder"
//                     placeholder="Enter description"
//                     rows={2}
//                     value={selectedStep.description}
//                     onChange={(e) =>
//                       setSteps((prevSteps) =>
//                         prevSteps.map((s) =>
//                           s.id === selectedStep.id
//                             ? { ...s, description: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Space>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ViewUploadedImage;
// import React, { useState, useEffect } from 'react';
// import {
//   Upload,
//   message,
//   Input,
//   Row,
//   Col,
//   Form,
//   Tag,
//   Space,
//   Divider,
//   Select,
// } from 'antd';
// import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
// import type { UploadFile, UploadProps } from 'antd';
// import './viewUploadedImageStyles.css';

// const { TextArea } = Input;
// const { Dragger } = Upload;
// const { Option } = Select;

// interface UploadImageFilesProps {
//   setSteps: (steps: any) => void;
//   steps: any;
// }

// export interface StepUpload {
//   id: number;
//   smiles_string: string;
//   imageFilesList: UploadFile[];
//   description: string;
// }

// export interface UploadedImageProps {
//   stepsData: any;
// }
// const stepsData =[
//     {
//         "id": 660,
//         "project_id": 134,
//         "folder_id": 291,
//         "reaction_scheme": null,
//         "description": "Description Lion",
//         "smiles_string": "C1=CC=CC=C1",
//         "sequence": 0,
//         "is_active": true,
//         "image_name": null,
//         "image_obj": "https://lexystech.blr1.digitaloceanspaces.com/newatom-eln-dev/null?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00P4WTU9Q88UKHZPQ9%2F20241223%2Fblr1%2Fs3%2Faws4_request&X-Amz-Date=20241223T073633Z&X-Amz-Expires=604800&X-Amz-Signature=5a90492d18121e7d6e1fde4d5ea586697d4645fdbfb659d4e829345496b4c53e&X-Amz-SignedHeaders=host&response-cache-control=no-cache&x-id=GetObject"
//     },
//     {
//         "id": 661,
//         "project_id": 134,
//         "folder_id": 291,
//         "reaction_scheme": null,
//         "description": "Painting-2",
//         "smiles_string": "C1C=CC=CC=1.C1C=CC=CC=1",
//         "sequence": 1,
//         "is_active": true,
//         "image_name": null,
//         "image_obj": "https://lexystech.blr1.digitaloceanspaces.com/newatom-eln-dev/null?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00P4WTU9Q88UKHZPQ9%2F20241223%2Fblr1%2Fs3%2Faws4_request&X-Amz-Date=20241223T073634Z&X-Amz-Expires=604800&X-Amz-Signature=f267ac0359eff3edbfda5a7529fc8f362ea3a30f1b0ee2bfb3f3b14ececc62d9&X-Amz-SignedHeaders=host&response-cache-control=no-cache&x-id=GetObject"
//     }
// ];

// const ViewUploadedImage = () => {
//   const [steps, setSteps] = useState<StepUpload[]>([]);
//   const [selectedStepId, setSelectedStepId] = useState<number>(stepsData[0]?.id || 1);

//   useEffect(() => {
//     // Initialize steps with stepsData
//     const initializedSteps = stepsData.map((data) => ({
//       id: data.id,
//       smiles_string: data.smiles_string || '',
//       imageFilesList: data.image_obj
//         ? [
//             {
//               uid: String(data.id),
//               name: `Image_${data.id}`,
//               url: data.image_obj,
//               status: 'done' as 'done',
//             },
//           ]
//         : [],
//       description: data.description || '',
//     }));
//     setSteps(initializedSteps);
//   }, []);

//   const handleAddStep = () => {
//     const newStep: StepUpload = {
//       id: steps.length + 1,
//       smiles_string: '',
//       imageFilesList: [],
//       description: '',
//     };
//     setSteps((prevSteps) => [...prevSteps, newStep]);
//   };

//   const handleDeleteStep = (stepId: number) => {
//     setSteps((prevSteps) => prevSteps.filter((step) => step.id !== stepId));
//     if (selectedStepId === stepId && steps.length > 1) {
//       setSelectedStepId(steps[0].id); // Reset to first step if the selected step is deleted
//     }
//   };

//   const handleChangeImageFiles = (stepId: number, fileList: UploadFile[]) => {
//     const updatedFileList = fileList.slice(-1).map((file) => ({
//       ...file,
//       status: 'done' as 'done',
//     }));

//     setSteps((prevSteps) =>
//       prevSteps.map((step) =>
//         step.id === stepId ? { ...step, imageFilesList: updatedFileList } : step
//       )
//     );
//   };

//   const handlePreviewImageFiles = async (file: UploadFile) => {
//     let src = file.url as string;
//     if (!src) {
//       src = await new Promise((resolve) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file.originFileObj as File);
//         reader.onload = () => resolve(reader.result as string);
//       });
//     }
//     const image = new Image();
//     image.src = src;
//     const imgWindow = window.open(src);
//     imgWindow?.document.write(image.outerHTML);
//   };

//   const beforeUploadImageFiles = (file: File) => {
//     const isImage = file.type.startsWith('image/');
//     if (!isImage) {
//       message.error('You can only upload image files!');
//       return Upload.LIST_IGNORE;
//     }
//     return true;
//   };

//   const draggerProps: UploadProps = {
//     name: 'file',
//     multiple: false,
//     beforeUpload: beforeUploadImageFiles,
//     onPreview: handlePreviewImageFiles,
//   };

//   const selectedStep = steps.find((step) => step.id === selectedStepId);

//   return (
//     <div>
//       <Form.Item label="Select Step" style={{ marginBottom: '24px' }}>
//         <Select
//           value={selectedStepId}
//           onChange={(value) => setSelectedStepId(value)}
//           style={{ width: '100%' }}
//         >
//           {steps.map((step) => (
//             <Option key={step.id} value={step.id}>
//               Step {step.id}
//             </Option>
//           ))}
//         </Select>
//       </Form.Item>

//       {selectedStep && (
//         <div
//           key={selectedStep.id}
//           style={{
//             marginBottom: '24px',
//             border: '1px solid #ddd',
//             padding: '16px',
//             position: 'relative',
//           }}
//         >
//           <Space direction="vertical" size={10} style={{ width: '100%' }}>
//             <div>
//               <Tag
//                 style={{
//                   backgroundColor: '#0044D3',
//                   color: 'white',
//                   marginRight: '10px',
//                   fontWeight: 600,
//                   textAlign: 'center',
//                 }}
//               >
//                 Step {selectedStep.id}
//               </Tag>
//               {steps.length > 1 && (
//                 <DeleteOutlined
//                   style={{
//                     color: 'red',
//                     fontSize: '16px',
//                     cursor: 'pointer',
//                     position: 'absolute',
//                     top: '16px',
//                     right: '16px',
//                   }}
//                   onClick={() => handleDeleteStep(selectedStep.id)}
//                 />
//               )}
//             </div>
//             <Row>
//               <Col span={11} className="upload_container">
//                 <Form.Item label="Upload Image" className="field_style">
//                   <Dragger
//                     {...draggerProps}
//                     fileList={selectedStep.imageFilesList}
//                     onChange={({ fileList }) => {
//                       const singleFile = fileList.slice(-1);
//                       handleChangeImageFiles(selectedStep.id, singleFile);
//                     }}
//                     className="custom-dragger RICHA"
//                   >
//                     <p className="ant-upload-drag-icon custom-icon">
//                       <UploadOutlined />
//                     </p>
//                     <p className="ant-upload-text">
//                       Click or drag image file to upload
//                     </p>
//                   </Dragger>
//                 </Form.Item>
//               </Col>
//               <Col
//                 span={2}
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//               >
//                 <Divider type="vertical" style={{ height: '100%' }} />
//               </Col>
//               <Col span={11}>
//                 <Form.Item
//                   label="SMILES string"
//                   className="field_style field_margin"
//                 >
//                   <Input
//                     className="custom-placeholder"
//                     placeholder="Enter SMILES string"
//                     size="large"
//                     value={selectedStep.smiles_string}
//                     onChange={(e) =>
//                       setSteps((prevSteps) =>
//                         prevSteps.map((s) =>
//                           s.id === selectedStep.id
//                             ? { ...s, smiles_string: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//                 <Form.Item label="Description" className="field_style">
//                   <TextArea
//                     className="custom-placeholder"
//                     placeholder="Enter description"
//                     rows={2}
//                     value={selectedStep.description}
//                     onChange={(e) =>
//                       setSteps((prevSteps) =>
//                         prevSteps.map((s) =>
//                           s.id === selectedStep.id
//                             ? { ...s, description: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Space>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ViewUploadedImage;

// import React, { useState, useEffect } from 'react';
// import {
//   Upload,
//   message,
//   Input,
//   Row,
//   Col,
//   Form,
//   Tag,
//   Space,
//   Divider,
//   Select,
// } from 'antd';
// import type { UploadFile, UploadProps } from 'antd';
// import './viewUploadedImageStyles.css';

// const { TextArea } = Input;
// const { Dragger } = Upload;
// const { Option } = Select;

// interface UploadImageFilesProps {
//   setSteps: (steps: any) => void;
//   steps: any;
// }

// export interface StepUpload {
//   id: number;
//   smiles_string: string;
//   imageFilesList: UploadFile[];
//   description: string;
// }

// export interface UploadedImageProps {
//   stepsData: any;
// }
// const stepsData = [
//   {
//     id: 660,
//     project_id: 134,
//     folder_id: 291,
//     reaction_scheme: null,
//     description: 'Description Lion',
//     smiles_string: 'C1=CC=CC=C1',
//     sequence: 0,
//     is_active: true,
//     image_name: null,
//     image_obj:
//       'https://lexystech.blr1.digitaloceanspaces.com/newatom-eln-dev/null?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00P4WTU9Q88UKHZPQ9%2F20241223%2Fblr1%2Fs3%2Faws4_request&X-Amz-Date=20241223T073633Z&X-Amz-Expires=604800&X-Amz-Signature=5a90492d18121e7d6e1fde4d5ea586697d4645fdbfb659d4e829345496b4c53e&X-Amz-SignedHeaders=host&response-cache-control=no-cache&x-id=GetObject',
//   },
//   {
//     id: 661,
//     project_id: 134,
//     folder_id: 291,
//     reaction_scheme: null,
//     description: 'Painting-2',
//     smiles_string: 'C1C=CC=CC=1.C1C=CC=CC=1',
//     sequence: 1,
//     is_active: true,
//     image_name: null,
//     image_obj:
//       'https://lexystech.blr1.digitaloceanspaces.com/newatom-eln-dev/null?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=DO00P4WTU9Q88UKHZPQ9%2F20241223%2Fblr1%2Fs3%2Faws4_request&X-Amz-Date=20241223T073634Z&X-Amz-Expires=604800&X-Amz-Signature=f267ac0359eff3edbfda5a7529fc8f362ea3a30f1b0ee2bfb3f3b14ececc62d9&X-Amz-SignedHeaders=host&response-cache-control=no-cache&x-id=GetObject',
//   },
// ];

// const ViewUploadedImage = () => {
//   const [steps, setSteps] = useState<StepUpload[]>([]);
//   const [selectedStepId, setSelectedStepId] = useState<number>(
//     stepsData[0]?.id || 1
//   );

//   useEffect(() => {
//     // Initialize steps with stepsData
//     const initializedSteps = stepsData.map((data) => ({
//       id: data.id,
//       smiles_string: data.smiles_string || '',
//       imageFilesList: data.image_obj
//         ? [
//             {
//               uid: String(data.id),
//               name: `Image_${data.id}`,
//               url: data.image_obj,
//               status: 'done' as 'done',
//             },
//           ]
//         : [],
//       description: data.description || '',
//     }));
//     setSteps(initializedSteps);
//   }, []);

//   const selectedStep = steps.find((step) => step.id === selectedStepId);

//   return (
//     <div>
//       <Form.Item label="Select Step" style={{ marginBottom: '24px' }}>
//         <Select
//           value={selectedStepId}
//           onChange={(value) => setSelectedStepId(value)}
//           style={{ width: '100%' }}
//         >
//           {steps.map((step) => (
//             <Option key={step.id} value={step.id}>
//               Step {step.id}
//             </Option>
//           ))}
//         </Select>
//       </Form.Item>

//       {selectedStep && (
//         <div
//           key={selectedStep.id}
//           style={{
//             marginBottom: '24px',
//             border: '1px solid #ddd',
//             padding: '16px',
//             position: 'relative',
//           }}
//         >
//           <Space direction="vertical" size={10} style={{ width: '100%' }}>
//             <div>
//               <Tag
//                 style={{
//                   backgroundColor: '#0044D3',
//                   color: 'white',
//                   marginRight: '10px',
//                   fontWeight: 600,
//                   textAlign: 'center',
//                 }}
//               >
//                 Step {selectedStep.id}
//               </Tag>
//             </div>
//             <Row>
//               <Col span={11} className="upload_container">
//                 <Form.Item label="Upload Image" className="field_style">
//                   <Upload
//                     multiple={false}
//                     listType="picture-card"
//                     fileList={selectedStep?.imageFilesList}
//                     beforeUpload={(file) => {
//                       const newFile: UploadFile = {
//                         uid: String(new Date().getTime()),
//                         name: file.name,
//                         status: 'done',
//                         url: URL.createObjectURL(file),
//                         originFileObj: file,
//                       };

//                       setSteps((prevSteps) =>
//                         prevSteps.map((step) =>
//                           step.id === selectedStep.id
//                             ? { ...step, imageFilesList: [newFile] }
//                             : step
//                         )
//                       );

//                       // Prevent actual upload to a server
//                       return false;
//                     }}
//                     onRemove={(file) => {
//                       setSteps((prevSteps) =>
//                         prevSteps.map((step) =>
//                           step.id === selectedStep.id
//                             ? {
//                                 ...step,
//                                 imageFilesList: step.imageFilesList.filter(
//                                   (item) => item.uid !== file.uid
//                                 ),
//                               }
//                             : step
//                         )
//                       );
//                     }}
//                   >
//                     {selectedStep?.imageFilesList.length < 1 && '+ Upload'}
//                   </Upload>
//                 </Form.Item>
//               </Col>
//               <Col
//                 span={2}
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                 }}
//               >
//                 <Divider type="vertical" style={{ height: '100%' }} />
//               </Col>
//               <Col span={11}>
//                 <Form.Item
//                   label="SMILES string"
//                   className="field_style field_margin"
//                 >
//                   <Input
//                     className="custom-placeholder"
//                     placeholder="Enter SMILES string"
//                     size="large"
//                     value={selectedStep.smiles_string}
//                     onChange={(e) =>
//                       setSteps((prevSteps) =>
//                         prevSteps.map((s) =>
//                           s.id === selectedStep.id
//                             ? { ...s, smiles_string: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//                 <Form.Item label="Description" className="field_style">
//                   <TextArea
//                     className="custom-placeholder"
//                     placeholder="Enter description"
//                     rows={2}
//                     value={selectedStep.description}
//                     onChange={(e) =>
//                       setSteps((prevSteps) =>
//                         prevSteps.map((s) =>
//                           s.id === selectedStep.id
//                             ? { ...s, description: e.target.value }
//                             : s
//                         )
//                       )
//                     }
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Space>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ViewUploadedImage;

import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Row,
  Col,
  Form,
  Tag,
  Space,
  Divider,
  Select,
  Image,
} from 'antd';
import './viewUploadedImageStyles.css';
import download_icon from 'assets/icons/download_icon_active.svg';

const { TextArea } = Input;
const { Option } = Select;

export interface StepUpload {
  id: number;
  smiles_string: string;
  imageFilesList: string; // URL list for images
  description: string;
  image_name: string;
}

export interface propStepsData {
  stepsData: StepsData[];
  stepNo: string
}

interface StepsData {
  id: number;
  smiles_string: string;
  image_obj: string;
  description: string;
  image_name: string;
}

const ViewUploadedImage = ({ stepsData,stepNo }: propStepsData) => {
  const [steps, setSteps] = useState<StepUpload[]>([]);
  const [selectedStepId, setSelectedStepId] = useState<number>(
    stepsData[0]?.id || 1
  );

  useEffect(() => {
    // Initialize steps with stepsData
    const initializedSteps = stepsData.map((data) => ({
      id: data.id,
      smiles_string: data.smiles_string || '',
      imageFilesList: data.image_obj || '',
      description: data.description || '',
      image_name: data.image_name || '',
    }));
    setSteps(initializedSteps);
    setSelectedStepId(stepsData[0]?.id || 1);
  }, [stepsData]);

  const selectedStep = steps.find((step) => step.id === selectedStepId);

  return (
    <div>
      <div className="mb-1">
        {steps?.map((step, index) => (
          <Tag
            key={index}
            onClick={() => setSelectedStepId(step.id)}
            style={{
              backgroundColor:
                selectedStepId === step.id ? '#0044D3' : '#f1f1f1',
              color: selectedStepId === step.id ? 'white' : 'black',
              marginRight: '10px',
              cursor: 'pointer',
            }}
            className="text-['9px'] font-semibold lg:text-['9px'] font-open-sans"
          >
            Step {stepNo}
          </Tag>
        ))}
      </div>
      <div>
        {selectedStep && (
          <div
            key={selectedStep.id}
            style={{
              marginBottom: '24px',
              border: '1px solid #ddd',
              padding: '16px',
              position: 'relative',
            }}
          >
            <Space direction="vertical" size={10} style={{ width: '100%' }}>
              <Row>
                <Col span={11} className="upload_container">
                  <Form.Item
                    label="Uploaded Image"
                    className="flex justify-center field_style"
                  >
                    <Image
                      src={selectedStep?.imageFilesList}
                      alt="Uploaded Image"
                      width={200}
                      height={200}
                      className="object-cover"
                    />
                    <Button
                      type="link"
                      className="flex justify-center p-0 border-none rounded-lg text-primary-dark border-tertiary-highlight-200 hover:opacity-75"
                    >
                      <a
                        href={selectedStep?.imageFilesList}
                        download={`Image_${selectedStep?.image_name}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex items-center gap-2">
                          <span className="text-xs font-semibold font-open-sans">
                            {`${selectedStep?.image_name}`}
                          </span>
                          <img src={download_icon} alt="download_icon" />
                        </div>
                      </a>
                    </Button>
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Divider type="vertical" style={{ height: '100%' }} />
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="SMILES string"
                    className="field_style field_margin"
                  >
                    <Input
                      disabled
                      className="custom-placeholder"
                      placeholder="Enter SMILES string"
                      size="large"
                      value={selectedStep.smiles_string}
                      onChange={(e) =>
                        setSteps((prevSteps) =>
                          prevSteps.map((s) =>
                            s.id === selectedStep.id
                              ? { ...s, smiles_string: e.target.value }
                              : s
                          )
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    className="field_style field_margin"
                  >
                    <TextArea
                      disabled
                      className="custom-placeholder"
                      placeholder="Enter description"
                      rows={2}
                      value={selectedStep.description}
                      onChange={(e) =>
                        setSteps((prevSteps) =>
                          prevSteps.map((s) =>
                            s.id === selectedStep.id
                              ? { ...s, description: e.target.value }
                              : s
                          )
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewUploadedImage;
