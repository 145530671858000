import { Button, Flex, Form, Spin, Tooltip } from 'antd';
import FolderDetails from '../components/FolderDetails';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  createFolder,
  getFolderDetails,
} from 'features/sectionHeadFlow/redux/slice';
import { AppDispatch, RootState } from 'app/store';
import { useCallback, useEffect, useState } from 'react';
import { getLoggedInUser, TabTitle } from 'utilities/helpers';
import {
  ArrowLeftOutlined,
  EditOutlined,
  ExperimentFilled,
  ExperimentOutlined,
} from '@ant-design/icons';
import FolderView from '../components/FolderView';
import RemarksModal from 'common/RemarksModal';
import {
  CHEMIST,
  PERMISSIONS,
  SECTION_HEAD,
  TECHNICAL_LEAD,
} from 'utilities/roleRights';
import { getprojectDetail } from 'features/proposalLeadFlow/dashboard/redux/slice';
import type { GetProp, UploadFile, UploadProps } from 'antd';

interface Step {
  reaction_scheme: string;
  smiles_string: string;
  description: string;
  id?: number;
  is_edit?: boolean;
}
export interface StepUpload {
  id: number;
  smiles_string: string;
  imageFilesList: UploadFile[];
  description: string;
}
export default function CreateFolder() {
  TabTitle('Create Folder | NewAtom ELN');
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const isEdit = pathname.split('/').at(-4) === 'edit';
  const [isFolderEditing, setIsFolderEditing] = useState(false);
  const [activeStep, setActiveStep] = useState<any>({
    reaction_scheme: '',
    smiles_string: '',
    description: '',
    index: 0,
  });
  const { role } = getLoggedInUser();
  const [selectedTechnicalLead, setSelectedTechnicalLead] = useState<any[]>([]);
  const [selectedProjectTeam, setSelectedProjectTeam] = useState<any[]>([]);
  const [lastSmiles, setLastSmiles] = useState<string>('');
  const [steps, setSteps] = useState<Step[]>([
    { reaction_scheme: '', smiles_string: '', description: '' }, // Tab1 initial state
  ]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0); // Active tab index
  const [newTabCleared, setNewTabCleared] = useState<boolean>(false); // Flag to clear editor on new tab
  const [ketcherSmiles, setKetcherSmiles] = useState<string>(''); // State to track SMILES string
  const [tabClicked, setTabClicked] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  const [is_image, setIs_image] = useState(false);
  const [stepsUpload, setStepsUpload] = useState<StepUpload[]>([
    { id: 1, smiles_string: '', imageFilesList: [], description: '' },
  ]);

  let count = 0;
  // console.log('isEdit', isEdit);
  const getProjectChemists = (data: any[], is_tl: boolean) => {
    const chemists = data
      ?.filter((ch: any) => ch.is_tl === is_tl)
      .map((chemist: any) => chemist.project_chemist?.id);

    const selectedUsers = is_tl
      ? technicalLeadList.filter((user: any) => chemists.includes(user.id))
      : projectTeamList.filter((user: any) => chemists.includes(user.id));

    if (is_tl) {
      setSelectedTechnicalLead(selectedUsers);
    } else {
      setSelectedProjectTeam(selectedUsers);
    }

    return chemists;
  };

  const resetState = () => {
    setSteps([
      {
        reaction_scheme: '',
        smiles_string: '',
        description: ' ',
      },
    ]);
    setNewTabCleared(true); // Trigger clearing the editor
    setKetcherSmiles('');
    setTabClicked(true);
    setActiveTab(0);
    setLastSmiles('');
    setActiveStep({
      reaction_scheme: '',
      smiles_string: '',
      description: ' ',
      index: 0,
    });
    setStepsUpload([
      { id: 1, smiles_string: '', imageFilesList: [], description: '' },
    ]);
  };

  const handleCancel = () => {
    // Reset states before navigating
    resetState();
    // Delay the navigation slightly to allow state updates
    setTimeout(() => {
      if (isEdit && isFolderEditing) {
        setIsFolderEditing(false);
      } else {
        navigate(-1);
      }
    }, 0); // Adjust the timeout as needed, or keep it at 0 for immediate navigation after state updates
  };

  const { id, folder_id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [hasFolderEditAccess, setHasFolderEditAccess] = useState(false);
  const { projectDetailData, getprojectDetailLoading } = useSelector(
    (state: RootState) => state.project
  );
  const {
    technicalLeadList,
    projectTeamList,
    createFolderLoading,
    folderDetails,
    folderDetailsLoading,
  } = useSelector((state: RootState) => state.sectionHead);

  if (id === 'undefined') {
    return <Navigate replace to={'/dashboard'} />;
  }

  const transformUserList = (list: any[], role: string = '') => {
    return list.map((item) => {
      count++;
      const isExistingUser = folderDetails?.project_chemists?.find(
        (user: any) => user.project_chemist?.id === item.id
      );

      const { id, ...itemWithouId } = item;

      return isExistingUser
        ? {
            ...item,
            id: isExistingUser?.id,
            chemist_id: item.id,
            sequence: count - 1,
            is_tl: role === 'tl' ? true : false,
            view_access: item.view_access ? false : false,
            edit_access: item.edit_access ? true : true,
            approve_access: item.approve_access ? true : true,
          }
        : {
            ...itemWithouId,
            chemist_id: item.id,
            sequence: count - 1,
            is_tl: role === 'tl' ? true : false,
            view_access: item.view_access ? false : false,
            edit_access: item.edit_access ? true : true,
            approve_access: item.approve_access ? true : true,
          };
    });
  };

  useEffect(() => {
    return () => {
      count = 0;
    };
  }, []);

  useEffect(() => {
    const creatorRole = folderDetails?.folder_creator?.role?.role;
    if (
      (creatorRole === SECTION_HEAD && role === SECTION_HEAD) ||
      (creatorRole === CHEMIST && role === TECHNICAL_LEAD)
    ) {
      setHasFolderEditAccess(true);
    } else {
      setHasFolderEditAccess(false);
    }
  }, [folderDetails]);

  const getFolderDetailsData = () => {
    dispatch(getFolderDetails({ id: folder_id })).then((res: any) => {
      if (res?.payload?.success) {
        const data = res?.payload?.data;
        form.setFieldsValue({
          folder_route_id: data?.folder_route?.id,
          step_no: data?.step_no,
        });
        form.setFieldsValue({
          is_image: data?.is_image,
        });

        const steps = data?.folder_route_schemes?.map((scheme: any) => {
          return {
            reaction_scheme: scheme.reaction_scheme,
            smiles_string: scheme.smiles_string,
            description: scheme.description,
            id: scheme.id,
            is_edit: false,
          };
        });

        if (steps) {
          setSteps(steps);
        }

        if (data?.is_image === true) {
          setIs_image(true);
          setStepsUpload(
            data?.folder_route_schemes?.map((scheme: any, index: number) => {
              return {
                id: index + 1,
                step_id: scheme.id,
                smiles_string: scheme.smiles_string,
                imageFilesList: [
                  {
                    url: scheme.image_obj,
                    // uid: scheme.id,
                    name: scheme.image_name,
                  },
                ],
                description: scheme.description,
                is_edit: false,
              };
            })
          );
        }

        //set selected TL and chemists
        if (data?.project_chemists?.length) {
          const tl = getProjectChemists(data?.project_chemists, true);
          const team = getProjectChemists(data?.project_chemists, false);
          form.setFieldsValue({
            technical_lead: tl,
            project_team: team,
          });
        }
      }
    });
  };

  //updating technical lead list when creating new folder
  const updateTLlist = useCallback(
    (list: any[]) => {
      const chemists = list.map((chemist: any) => {
        return chemist.project_chemist?.id;
      });
      const selectedUsers = technicalLeadList.filter((user: any) =>
        chemists.includes(user.id)
      );
      setSelectedTechnicalLead(selectedUsers);
      form.setFieldsValue({
        technical_lead: chemists,
      });
    },
    [technicalLeadList]
  );

  //get project details if not present
  useEffect(() => {
    const payload = {
      id: Number(id),
    };
    if (!projectDetailData?.id && !isEdit) {
      dispatch(getprojectDetail(payload)).then((res: any) => {
        if (res?.payload?.success) {
          updateTLlist(res?.payload?.data?.technical_leads);
        }
      });
    } else {
      if (!isEdit) {
        updateTLlist(projectDetailData?.technical_leads);
      }
    }
  }, [id, projectDetailData, updateTLlist]);

  //fetching folder details
  useEffect(() => {
    if (isEdit) {
      getFolderDetailsData();
    }
  }, [
    folder_id,
    isEdit,
    id,
    isFolderEditing,
    technicalLeadList,
    projectTeamList,
  ]);

  const onValuesChange = (changedVal: any, values: any) => {
    if (changedVal.technical_lead) {
      const selectedUsers = technicalLeadList.filter((user: any) =>
        changedVal.technical_lead.includes(user.id)
      );
      setSelectedTechnicalLead(selectedUsers);
    }

    if (changedVal.project_team) {
      const selectedUsers = projectTeamList.filter((user: any) =>
        changedVal.project_team.includes(user.id)
      );
      setSelectedProjectTeam(selectedUsers);
    }
  };
  console.log('stepsUpload', stepsUpload);
  // const processStepsUpload = (stepsUpload: any[]) => {
  //   const images: File[] = [];
  //   const transformedSteps = stepsUpload?.map(
  //     ({ id, imageFilesList, ...rest }) => {
  //       if (imageFilesList && Array.isArray(imageFilesList)) {
  //         imageFilesList.forEach((image: any) => {
  //           if (image.originFileObj) {
  //             // Handle local files
  //             images.push(image.originFileObj);
  //           } else if (image.url) {
  //             // Handle URLs if needed
  //             const file = new File([], image.name);
  //             Object.defineProperty(file, 'url', {
  //               value: image.url,
  //               writable: false,
  //             });
  //             images.push(file); // Add file with URL property
  //           }
  //         });
  //       }
  //       return rest; // Exclude 'id' and 'imageFilesList'
  //     }
  //   );
  //   const transformedStepsWithId = transformedSteps.map((step) => ({
  //     // ...step,
  //     id: step?.step_id,
  //     smiles_string: step?.smiles_string,
  //     description: step?.description,
  //     is_edit: step?.is_edit,
  //   }));
  //   return { transformedStepsWithId, images };
  // };
  // const processStepsUpload = (stepsUpload: any[]) => {
  //   const images: File[] = [];
  //   const transformedSteps = stepsUpload?.map(
  //     ({ id, imageFilesList, ...rest }, index) => {
  //       // Initialize base step data
  //       const baseStep = {
  //         id: rest?.step_id,
  //         smiles_string: rest?.smiles_string,
  //         description: rest?.description,
  //         is_edit: false,
  //       };

  //       // Handle image upload
  //       if (
  //         imageFilesList &&
  //         Array.isArray(imageFilesList) &&
  //         imageFilesList[0]
  //       ) {
  //         const currentImage = imageFilesList[0];

  //         if (currentImage?.originFileObj) {
  //           // This is a new file upload
  //           images[index] = currentImage.originFileObj;
  //         } else if (currentImage?.url) {
  //           // For existing images with URL, create a File object with the URL
  //           const file = new File([], currentImage.name || 'image.jpg', {
  //             type: 'image/jpeg',
  //           });
  //           // Add the URL property to the File object
  //           Object.defineProperty(file, 'url', {
  //             value: currentImage.url,
  //             writable: false,
  //           });
  //           images[index] = file;
  //         }
  //       }

  //       return baseStep;
  //     }
  //   );

  //   // Remove any undefined elements from the images array
  //   const cleanedImages = images.filter((image) => image !== undefined);

  //   return { transformedSteps, images: cleanedImages };
  // };
  // Update the processStepsUpload function to handle is_edit correctly
  // const processStepsUpload = (stepsUpload: any[]) => {
  //   const images: File[] = [];
  //   const transformedSteps = stepsUpload?.map((step) => {
  //     const { id, imageFilesList, ...rest } = step;

  //     // Handle image file if present
  //     if (imageFilesList && imageFilesList[0]) {
  //       if (imageFilesList[0].originFileObj) {
  //         // This is a new file upload
  //         images.push(imageFilesList[0].originFileObj);
  //       } else if (imageFilesList[0].url) {
  //         // This is an existing image
  //         const file = new File([], imageFilesList[0].name);
  //         Object.defineProperty(file, 'url', {
  //           value: imageFilesList[0].url,
  //           writable: false,
  //         });
  //         images.push(file);
  //       }
  //     }

  //     // Return transformed step data
  //     return {
  //       id: rest.step_id,
  //       smiles_string: rest.smiles_string,
  //       description: rest.description,
  //       is_edit: rest.is_edit || false, // Preserve is_edit state for each step
  //     };
  //   });

  //   return { transformedStepsWithId: transformedSteps, images };
  // };
  const processStepsUpload = (stepsUpload: any[]) => {
    const images: File[] = [];
    const transformedSteps = stepsUpload?.map((step) => {
      const { id, imageFilesList, ...rest } = step;

      // Handle image file if present
      if (imageFilesList && imageFilesList[0]) {
        if (imageFilesList[0].originFileObj) {
          // This is a new file upload
          images.push(imageFilesList[0].originFileObj);
        } else if (imageFilesList[0].url) {
          // This is an existing image
          const file = new File([], imageFilesList[0].name);
          Object.defineProperty(file, 'url', {
            value: imageFilesList[0].url,
            writable: false,
          });
          images.push(file);
        }
      }

      // Return transformed step data with is_active flag
      return {
        id: rest.step_id,
        smiles_string: rest.smiles_string,
        description: rest.description,
        is_edit: rest.is_edit || false,
        is_active: rest.is_active !== false, // Set is_active to true by default unless explicitly set to false
      };
    });

    return { transformedStepsWithId: transformedSteps, images };
  };
  const finalSubmit = (values: any, remark = '') => {
    const deletedUsers = folderDetails?.project_chemists
      ?.filter(
        (user: any) =>
          ![...selectedTechnicalLead, ...selectedProjectTeam].some(
            (ch: any) => ch.id === user?.project_chemist.id
          )
      )
      .map((user: any) => ({ is_active: false, id: user?.id }));

    //--------------------

    // const { transformedStepsWithId, images } = processStepsUpload(stepsUpload);
    const { transformedStepsWithId, images } = processStepsUpload(stepsUpload);
    console.log('transformedSteps!!!!', transformedStepsWithId);
    console.log('images!!!!!', images);
    //----------------------
    const payload = {
      ...values,
      project_id: id,
      steps: is_image === false ? steps : transformedStepsWithId,
      is_image,
      approvers: isEdit
        ? [
            ...transformUserList(selectedTechnicalLead, 'tl'),
            ...transformUserList(selectedProjectTeam),
            ...deletedUsers,
          ]
        : [
            ...transformUserList(selectedTechnicalLead, 'tl'),
            ...transformUserList(selectedProjectTeam),
          ],
    };
    if (isEdit) {
      payload.change_remarks = remark;
      payload.is_edit = true;
      payload.id = folder_id;
    }
    //----create formData for image files-----------
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else if (value !== undefined && value !== null) {
        formData.append(key, value as string | Blob);
      }
    });

    // Add the 'images' array to the formData

    images.forEach((image, index) => {
      formData.append(`images`, image);
      // console.log(`images[${index}]`, image);
    });

    // console.log('payload', payload);
    // Log the FormData entries

    // Array.from(formData.entries()).forEach(([key, value]) => {
    //   console.log(`FormData Entry -> Key: ${key}, Value:`, value);
    // });

    dispatch(createFolder(formData)).then((res: any) => {
      if (res.payload.success) {
        if (isEdit && isFolderEditing) {
          setIsFolderEditing(false);
          getFolderDetailsData();
        }
        navigate('/projects/' + id, { replace: true });
      }
    });
  };

  const onFolderCreate = (values: any, remark = '') => {
    if (!isEdit) {
      finalSubmit(values, remark);
    } else {
      if (isRemarksModalOpen) {
        setIsRemarksModalOpen(false);
        finalSubmit(values, remark);
      } else {
        setIsRemarksModalOpen(true);
      }
    }
  };

  //------In Drawing type-> Upload Image files-------------------
  const handleAddStepUpload = () => {
    const newStep: StepUpload = {
      id: stepsUpload?.length + 1,
      smiles_string: '',
      imageFilesList: [],
      description: '',
    };
    setStepsUpload((prevSteps) => [...prevSteps, newStep]);
  };

  return (
    <Flex vertical gap="1.25rem">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-primary font-open-sans">
          {isEdit && isFolderEditing
            ? 'Edit Folder'
            : isEdit
              ? 'Folder'
              : 'Create Folder'}
        </h1>
        {isEdit && (
          <div className="flex items-center gap-2">
            {!isFolderEditing && (
              <Button
                size="small"
                type="text"
                icon={<ArrowLeftOutlined />}
                className="font-open-sans h-7 hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
                onClick={() => {
                  navigate(`/folders/${folderDetails?.project?.id}`);
                  resetState();
                }}
              >
                Go back
              </Button>
            )}
            {!isFolderEditing &&
              PERMISSIONS.canEditFolder(role) &&
              hasFolderEditAccess && (
                <>
                  <Button
                    className="bg-transparent h-7 hidden md:flex hover:!text-white hover:!bg-primary !px-2 font-open-sans border-primary text-primary"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setIsFolderEditing(true);
                      setActiveTab(activeStep.index);
                    }}
                  >
                    Edit
                  </Button>
                  <Tooltip title={'Edit'} overlayClassName="custom-tooltip">
                    <Button
                      className="bg-transparent h-7 block md:hidden hover:!text-white hover:!bg-primary !px-2 font-open-sans border-primary text-primary"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setIsFolderEditing(true);
                        setActiveTab(activeStep.index);
                      }}
                    />
                  </Tooltip>
                </>
              )}
            <Button
              onClick={() => navigate(`/experiments/${id}/${folder_id}`)}
              icon={<ExperimentOutlined />}
              className=" hover:!bg-primary h-7 sm:flex hidden text-primary border border-primary hover:!text-white font-open-sans"
              type="text"
            >
              Go to experiments
            </Button>
            <Tooltip
              title={'Go to experiments'}
              overlayClassName="custom-tooltip"
            >
              <Button
                onClick={() => navigate(`/experiments/${id}/${folder_id}`)}
                icon={<ExperimentOutlined />}
                className=" hover:!bg-primary h-7 block sm:hidden text-primary border border-primary hover:!text-white font-open-sans"
                type="text"
              />
            </Tooltip>
          </div>
        )}
      </div>
      <Form
        onFinish={onFolderCreate}
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
        className="flex flex-col gap-5"
        initialValues={{
          is_image: false, // Set the default value in Form's initialValues
        }}
      >
        {/* folder details form */}
        <Spin
          spinning={(folderDetailsLoading && isEdit) || getprojectDetailLoading}
        >
          {isFolderEditing || !isEdit ? (
            <FolderDetails
              form={form}
              selectedTechnicalLead={selectedTechnicalLead}
              setSelectedTechnicalLead={setSelectedTechnicalLead}
              selectedProjectTeam={selectedProjectTeam}
              setSelectedProjectTeam={setSelectedProjectTeam}
              steps={steps}
              setSteps={setSteps}
              lastSmiles={lastSmiles}
              setLastSmiles={setLastSmiles}
              newTabCleared={newTabCleared}
              setNewTabCleared={setNewTabCleared}
              ketcherSmiles={ketcherSmiles}
              setKetcherSmiles={setKetcherSmiles}
              tabClicked={tabClicked}
              setTabClicked={setTabClicked}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setStepsUpload={setStepsUpload}
              stepsUpload={stepsUpload}
              handleAddStepUpload={handleAddStepUpload}
              setIs_image={setIs_image}
              is_image={is_image}
            >
              <Flex justify="center" gap={'1rem'} className="mt-4 ">
                <Button
                  type="text"
                  className="text-primary h-9 font-open-sans"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                {!isEdit && (
                  <Button
                    htmlType="reset"
                    className="font-open-sans h-9 hover:!bg-white hover:!text-primary font-semibold border hover:border-primary-dark"
                    onClick={resetState}
                  >
                    Clear
                  </Button>
                )}
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={createFolderLoading}
                  className="text-white h-9 bg-primary-dark font-open-sans hover:!bg-white hover:!text-primary font-semibold border border-primary-dark"
                >
                  {isEdit ? 'Update' : 'Create'}
                </Button>
              </Flex>
            </FolderDetails>
          ) : (
            <FolderView
              steps={steps}
              data={folderDetails}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
            />
          )}

          {/*change remarks modal */}
          <RemarksModal
            isOpen={isRemarksModalOpen}
            onApprove={(value) => {
              const values = form.getFieldsValue();
              onFolderCreate(values, value);
            }}
            loading={createFolderLoading}
            onClose={() => setIsRemarksModalOpen(false)}
          />
        </Spin>
      </Form>
    </Flex>
  );
}
