// import React, { useState, useEffect, useRef } from 'react';
// import 'miew/dist/miew.min.css';
// import { StandaloneStructServiceProvider } from 'ketcher-standalone';
// import { Editor } from 'ketcher-react';
// import { Ketcher } from 'ketcher-core';
// import 'ketcher-react/dist/index.css';
// import Miew from 'miew';
// import styled from '@emotion/styled';

// // Ensure Miew is available globally
// (window as any).Miew = Miew;

// const CenteredContainer = styled.div`
//   margin-top: 7px;
//   margin-bottom: 20px;
//   display: flex;
//   justify-content: start;
//   align-items: start;
//   max-height: 100vh;
//   max-width: 100vw;
// `;

// const GridWrapper = styled.div`
//   height: 55vh;
//   width: 100%;
//   overflow: hidden;
//   display: flex;
//   grid-template-columns: 1fr 270px 320px;
//   grid-template-rows: 1fr;
//   gap: 0px 0px;
//   grid-template-areas: 'Ketcher Panel Output';

//   & > div {
//     border: 1px solid #d9d9d9;
//     border-radius: 6px;
//   }
// `;

// const structServiceProvider = new StandaloneStructServiceProvider();

// interface KetcherDemoProps {
//   onSmilesChange: (smiles: string) => void;
//   moleculeToLoad?: string; // Prop to load a molecule into the editor
//   clearEditor?: boolean; // Prop to trigger clearing the editor
//   tabClicked: boolean;
//   setTabClicked: (value: boolean) => void;
//   setNewTabCleared: (value: boolean) => void;
//   lastSmiles: string;
//   setLastSmiles: (value: string) => void;
// }

// const KetcherStructEditor: React.FC<KetcherDemoProps> = ({
//   onSmilesChange,
//   moleculeToLoad,
//   clearEditor,
//   tabClicked,
//   setTabClicked,
//   setNewTabCleared,
//   lastSmiles,
//   setLastSmiles,
// }) => {
//   const ketcherRef = useRef<Ketcher | null>(null);
//   // const [lastSmiles, setLastSmiles] = useState<string>('');

//   const handleOnInit = (ketcher: Ketcher) => {
//     ketcherRef.current = ketcher;
//     (window as any).ketcher = ketcher;
//   };

//   useEffect(() => {
//     const fetchSmiles = async () => {
//       if (ketcherRef.current) {
//         const smiles = await ketcherRef.current.getSmiles();
//         if (smiles !== lastSmiles) {
//           setLastSmiles(smiles);
//           onSmilesChange(smiles); // Notify parent of the SMILES change
//         }
//       }
//     };

//     const interval = setInterval(fetchSmiles, 1000); // Poll every second

//     return () => clearInterval(interval); // Cleanup the interval on component unmount
//   }, [lastSmiles, onSmilesChange]);

//   useEffect(() => {
//     if (clearEditor && ketcherRef.current) {
//       ketcherRef.current.setMolecule(''); // Clear the editor
//     }
//     setNewTabCleared(false); // Reset the new tab cleared state
//   }, [clearEditor]);

//   useEffect(() => {
//     setTabClicked(false); // Reset the tab clicked state
//     if (moleculeToLoad && ketcherRef.current) {
//       ketcherRef.current.setMolecule(moleculeToLoad); // Load the molecule
//     }
//   }, [tabClicked]);

//   return (
//     <CenteredContainer>
//       <GridWrapper onClick={(e) => e.preventDefault()}>
//         <Editor
//           errorHandler={(message: string) => null}
//           staticResourcesUrl={''}
//           structServiceProvider={structServiceProvider}
//           onInit={handleOnInit}
//         />
//       </GridWrapper>
//     </CenteredContainer>
//   );
// };

// export default KetcherStructEditor;
import React, { useState, useEffect, useRef } from 'react';
import 'miew/dist/miew.min.css';
import { StandaloneStructServiceProvider } from 'ketcher-standalone';
import { Editor } from 'ketcher-react';
import { Ketcher } from 'ketcher-core';
import 'ketcher-react/dist/index.css';
import Miew from 'miew';
import styled from '@emotion/styled';

// Ensure Miew is available globally
(window as any).Miew = Miew;

const CenteredContainer = styled.div`
  margin-top: 7px;
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
  align-items: start;
  max-height: 100vh;
  max-width: 100vw;
`;

const GridWrapper = styled.div`
  height: 55vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  grid-template-columns: 1fr 270px 320px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'Ketcher Panel Output';

  & > div {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }
`;

const structServiceProvider = new StandaloneStructServiceProvider();

interface KetcherDemoProps {
  onSmilesChange: (smiles: string) => void;
  moleculeToLoad?: string; // Prop to load a molecule into the editor
  clearEditor?: boolean; // Prop to trigger clearing the editor
  tabClicked: boolean;
  setTabClicked: (value: boolean) => void;
  setNewTabCleared: (value: boolean) => void;
  lastSmiles: string;
  setLastSmiles: (value: string) => void;
}

const KetcherStructEditor: React.FC<KetcherDemoProps> = ({
  onSmilesChange,
  moleculeToLoad,
  clearEditor,
  tabClicked,
  setTabClicked,
  setNewTabCleared,
  lastSmiles,
  setLastSmiles,
}) => {
  const ketcherRef = useRef<Ketcher | null>(null);

  const handleOnInit = (ketcher: Ketcher) => {
    ketcherRef.current = ketcher;
    (window as any).ketcher = ketcher;
  };

  const getBase64EncodedMolfile = async () => {
    if (ketcherRef.current) {
      const molfile = await ketcherRef.current.getMolfile();
      const base64Encoded = btoa(molfile);
      console.log(base64Encoded); // You can store or use this base64 encoded string as needed
    }
  };

  useEffect(() => {
    const fetchSmiles = async () => {
      if (ketcherRef.current) {
        const smiles = await ketcherRef.current.getSmiles();
        if (smiles !== lastSmiles) {
          setLastSmiles(smiles);
          onSmilesChange(smiles); // Notify parent of the SMILES change
        }
      }
    };

    const interval = setInterval(fetchSmiles, 1000); // Poll every second

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [lastSmiles, onSmilesChange]);

  useEffect(() => {
    if (clearEditor && ketcherRef.current) {
      ketcherRef.current.setMolecule(''); // Clear the editor
    }
    setNewTabCleared(false); // Reset the new tab cleared state
  }, [clearEditor]);

  useEffect(() => {
    setTabClicked(false); // Reset the tab clicked state
    if (moleculeToLoad && ketcherRef.current) {
      ketcherRef.current.setMolecule(moleculeToLoad); // Load the molecule
    }
  }, [tabClicked]);

  return (
    <CenteredContainer>
      <GridWrapper onClick={(e) => e.preventDefault()}>
        <Editor
          errorHandler={(message: string) => null}
          staticResourcesUrl={''}
          structServiceProvider={structServiceProvider}
          onInit={handleOnInit}
        />
        {/* <button onClick={getBase64EncodedMolfile}>
          Get Base64 Encoded Molfile
        </button> */}
      </GridWrapper>
    </CenteredContainer>
  );
};

export default KetcherStructEditor;
