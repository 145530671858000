import {
  Button,
  Col,
  Form,
  FormInstance,
  Row,
  Space,
  Select,
  message,
} from 'antd';
import Card from 'common/Card';
import FormInput from 'common/FormInput';
import plusIcon from 'assets/icons/plus.svg';
import Label from 'common/Label';
import { useCallback, useEffect, useState } from 'react';
import { AppDispatch, RootState } from 'app/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFolderRouteList,
  getProjectTeamList,
  getTechnicalLeadList,
} from 'features/sectionHeadFlow/redux/slice';
import { debounce } from 'lodash';
import AccessAssigner from 'common/AccessAssigner';
import Spacer from 'common/Spacer';
import { useResponsiveness } from 'hooks/useResponsiveness';
import { PlusOutlined } from '@ant-design/icons';
// import KetcherDemo from '../chemicalStructureKetcher/index';
import KetcherTabs from '../chemicalStructureKetcher/KetcherTabs';
import deleteIcon from 'assets/icons/delete.svg';
import { SECTION_HEAD } from 'utilities/roleRights';
import { getLoggedInUser } from 'utilities/helpers';
import './folderDetailsStyle.css';
import DrawingType_UploadImageFiles from './DrawingType_UploadImageFiles';

// import type { GetProp, UploadFile, UploadProps } from 'antd';
// import { StepUpload } from '../pages/CreateFolder';

const { Option } = Select;

type TSteps = {
  reaction_scheme: string;
  description: string;
  smiles_string: string;
};

export type TProps = {
  children?: string | JSX.Element;
  form: FormInstance<any>;
  steps: any[];
  setSteps: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedTechnicalLead: React.Dispatch<React.SetStateAction<any[]>>;
  selectedTechnicalLead: any[];
  selectedProjectTeam: any[];
  setSelectedProjectTeam: React.Dispatch<React.SetStateAction<any[]>>;
  lastSmiles: string;
  setLastSmiles: React.Dispatch<React.SetStateAction<string>>;
  newTabCleared: boolean;
  setNewTabCleared: React.Dispatch<React.SetStateAction<boolean>>;
  ketcherSmiles: string;
  setKetcherSmiles: React.Dispatch<React.SetStateAction<string>>;
  tabClicked: boolean;
  setTabClicked: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  setStepsUpload: React.Dispatch<React.SetStateAction<any[]>>;
  stepsUpload: any[];
  handleAddStepUpload: () => void;
  setIs_image: React.Dispatch<React.SetStateAction<boolean>>;
  is_image: boolean;
};

// interface Step {
//   id: number;
//   smileyString: string;
//   imageFilesList: UploadFile[];
//   description: string;
// }

export default function FolderDetails({
  children,
  form,
  steps,
  setSteps,
  setSelectedTechnicalLead,
  selectedTechnicalLead,
  selectedProjectTeam,
  setSelectedProjectTeam,
  lastSmiles,
  setLastSmiles,
  newTabCleared,
  setNewTabCleared,
  ketcherSmiles,
  setKetcherSmiles,
  tabClicked,
  setTabClicked,
  activeTab,
  setActiveTab,
  setStepsUpload,
  stepsUpload,
  handleAddStepUpload,
  setIs_image,
  is_image,
}: TProps) {
  const dispatch: AppDispatch = useDispatch();
  const [routeSearch, setRouteSearch] = useState('');
  const { role } = getLoggedInUser();

  const { folderRouteList, technicalLeadList, projectTeamList } = useSelector(
    (state: RootState) => state.sectionHead
  );
  const { xs, sm, md } = useResponsiveness();

  useEffect(() => {
    const payload = {
      search: routeSearch,
    };
    dispatch(getFolderRouteList(payload));
  }, [routeSearch]);

  useEffect(() => {
    dispatch(getTechnicalLeadList());
  }, []);

  useEffect(() => {
    dispatch(getProjectTeamList());
  }, []);

  const handleAddStep = () => {
    const currentStep = steps[activeTab];
    // Save current state
    const updatedSteps = steps.map((step, i) =>
      i === activeTab ? { ...step, smiles_string: ketcherSmiles } : step
    );

    // Add new step and set it as active
    setSteps([
      ...updatedSteps,
      { reaction_scheme: '', smiles_string: '', description: '' },
    ]);
    setActiveTab(steps.length); // Set the newly added tab as active
    setNewTabCleared(true); // Trigger clearing the editor
    setTabClicked(true);
  };

  const renderedFolderList = folderRouteList.map((item) => {
    return { label: item.route, value: item.id };
  });

  const renderedTechnicalLeadList = technicalLeadList.map((item) => {
    return {
      label: item.display_name,
      value: item.id,
      disabled: selectedProjectTeam.some((user) => user.id === item.id),
    };
  });

  const renderedProjectTeamList = projectTeamList.map((item) => {
    return {
      label: item.display_name,
      value: item.id,
      disabled: selectedTechnicalLead.some((user) => user.id === item.id),
    };
  });

  const handleRouteChange = useCallback(
    debounce((value) => {
      setRouteSearch(value);
    }, 500),
    []
  );
  const onRouteSearch = (value: string) => {
    handleRouteChange(value);
  };

  const handleUpdateUserAccess = (
    access: string[],
    role: string,
    id: string
  ) => {
    const view = access.includes('view_access') ? true : false;
    const edit = access.includes('edit_access') ? true : false;
    const approve = access.includes('approve_access') ? true : false;

    if (role === 'technical_lead') {
      const updatedUser = selectedTechnicalLead.map((user) =>
        user.id === id
          ? {
              ...user,
              view_access: view,
              edit_access: edit,
              approve_access: approve,
            }
          : user
      );
      setSelectedTechnicalLead(updatedUser);
    }

    if (role === 'remove_technical_lead') {
      const users = [...selectedTechnicalLead];
      const filteredUsers = users.filter((user) => user.id !== id);
      setSelectedTechnicalLead(filteredUsers);

      const selectedValues = form.getFieldValue('technical_lead');
      const selectedUsers = selectedValues.filter(
        (userId: any) => userId !== id
      );
      form.setFieldsValue({ technical_lead: selectedUsers });
    }

    if (role === 'project_team') {
      const updatedUser = selectedProjectTeam.map((user) =>
        user.id === id
          ? {
              ...user,
              view_access: view,
              edit_access: edit,
              approve_access: approve,
            }
          : user
      );
      setSelectedProjectTeam(updatedUser);
    }

    if (role === 'remove_project_team') {
      const users = [...selectedProjectTeam];
      const filteredUsers = users.filter((user) => user.id !== id);
      setSelectedProjectTeam(filteredUsers);

      const selectedValues = form.getFieldValue('project_team');
      const selectedUsers = selectedValues.filter(
        (userId: any) => userId !== id
      );
      form.setFieldsValue({ project_team: selectedUsers });
    }
  };
  const handleDrawingTypeChange = (value: boolean) => {
    setIs_image(value);

    // Clear the stepsUpload if the drawing type is changed
    if (value === false) {
      setStepsUpload([
        { id: 1, smiles_string: '', imageFilesList: [], description: '' },
      ]);
    }
  };

  return (
    <Card>
      <>
        <div className="drawingType">
          <Form.Item
            label="Drawing Type"
            name="is_image"
            rules={[{ required: true, message: 'Drawing type is required' }]}
          >
            <Select
              placeholder="Select drawing type"
              // value={drawing_type}
              onChange={(value) => {
                handleDrawingTypeChange(value);
              }}
            >
              <Option value={true}>Upload Image</Option>
              <Option value={false}>Draw in Editor</Option>
            </Select>
          </Form.Item>
        </div>

        <Row gutter={20} className="">
          <Col span={24} sm={{ span: 8 }}>
            <FormInput
              name="folder_route_id"
              label="Route"
              type="select"
              onSearch={onRouteSearch}
              defaultStyle={true}
              placeholder="Select a route"
              options={renderedFolderList}
              required
              validateMsg="Route is required"
            />
          </Col>

          <Col span={24} sm={{ span: 8 }} lg={{ span: 4 }}>
            {/* <Spacer xs={xs}>
              <Button
                size="large"
                type="primary"
                className={`w-full text-xs font-semibold bg-transparent font-open-sans ${steps.length < 1 ? 'border text-tertiary border-tertiary-highlight-200 bg-gray-400' : 'border text-secondary-highlight-100 border-secondary-highlight-100 hover:!bg-primary hover:!text-white'}`}
                onClick={
                  is_image === false ? handleAddStep : handleAddStepUpload
                }
                disabled={steps.length < 1}
                icon={<PlusOutlined />}
              >
                Add Step
              </Button>
            </Spacer> */}
            <FormInput
              name="step_no"
              label="Step No."
              prefix={'Step: '}
              type="number"
              required
              rules={[
                {
                  required: true,
                  message: 'Step number is required',
                },
                {
                  validator: (_, value) => {
                    if (isNaN(value) || Math.sign(value) !== 1) {
                      return Promise.reject(new Error('Enter valid step number'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              inputClasses="!py-2 font-open-sans shadow-none border disabled:!text-secondary-black focus-within:border-primary-dark !text-sm hover:border-tertiary-highlight-200"
            />
          </Col>
        </Row>

        {is_image === false ? (
          <KetcherTabs
            steps={steps}
            setSteps={setSteps}
            handleAddStep={handleAddStep}
            lastSmiles={lastSmiles}
            setLastSmiles={setLastSmiles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            newTabCleared={newTabCleared}
            setNewTabCleared={setNewTabCleared}
            ketcherSmiles={ketcherSmiles}
            setKetcherSmiles={setKetcherSmiles}
            tabClicked={tabClicked}
            setTabClicked={setTabClicked}
          />
        ) : (
          <>
            <div>
              <DrawingType_UploadImageFiles
                // handleAddStep={handleAddStepUpload}
                setSteps={setStepsUpload}
                steps={stepsUpload}
              />
            </div>
          </>
        )}

        {role === SECTION_HEAD && (
          <Row gutter={20}>
            <Col span={24} md={{ span: 8 }}>
              <FormInput
                name="technical_lead"
                label="Assign Technical Lead"
                type="select"
                multiple
                removeIcon={
                  <img
                    src={deleteIcon}
                    className="cursor-pointer hover:opacity-75"
                  />
                }
                defaultStyle={true}
                placeholder="Select technical lead"
                required
                validateMsg="Assign technical lead is required"
                options={renderedTechnicalLeadList}
              />
            </Col>
          </Row>
        )}
        <Row gutter={20} className="mt-2">
          <Col span={24} md={{ span: 8 }}>
            <FormInput
              name="project_team"
              label="Assign Project Team"
              type="select"
              multiple
              defaultStyle={true}
              placeholder="Select project team"
              options={renderedProjectTeamList}
              required
              removeIcon={
                <img
                  src={deleteIcon}
                  className="cursor-pointer hover:opacity-75"
                />
              }
              validateMsg="Assign project team is required"
            />
          </Col>
        </Row>
        {!children ? '' : children}
      </>
    </Card>
  );
}
